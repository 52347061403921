import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

import * as actions from '../../../redux/actions/index';
import LogTypes from '../../../config/LogTypes';
import { ROLES } from '../../../config/UserRoles';

export default function CreatePosition() {
  const { register, handleSubmit } = useForm();
  const { id } = useParams();
  const history = useHistory();
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState();

  useEffect(() => {
    dispatch(actions.departmentAll());
    return () => {};
  }, []);

  const genDepartmentOption = () =>
    _.map(department, (_department) => {
      return (
        <option
          key={_department.department_id}
          value={_department.department_id}
        >
          {_department.department_name}
        </option>
      );
    });

  const onSubmit = (data, e) => {
    dispatch(
      actions.logCreate(
        me.employee_id,
        LogTypes.SYSTEM_EDIT,
        `สร้างตำแหน่งงาน ${data.position_name}  `,
      ),
    );

    dispatch(
      actions.positionPost({ ...data, department_id: selectedDepartmentId }),
    ).then(() => {
      console.log('เพิ่มตำแหน่งสำเร็จ');

      dispatch(actions.positionAllByDepartment(me.department_id));
      history.push(`/hrms/department/details/${id}`);
    });
  };

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">เพิ่มตำแหน่ง</h2>
      <div className="flex justify-between py-6">
        <Link to={`/hrms/department/details/${id}`}>
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {' '}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อตำแหน่ง
                </label>
                <input
                  name="position_name"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อตำแหน่ง"
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  แผนก
                </label>
                <select
                  name="department_id"
                  required
                  ref={register}
                  onChange={(event) =>
                    setSelectedDepartmentId(event.target.value)
                  }
                  disabled={me.employee_role !== ROLES.SUPER_USER}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                >
                  <option>กรุณาเลือกแผนก</option>
                  {genDepartmentOption()}
                </select>
              </div>

              <div className="relative w-full mb-3">
                <div className="text-center mt-6">
                  <Button isFullWidth colorScheme="blue" type="submit">
                    เพิ่ม
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
