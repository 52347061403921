import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions';
import { firebaseAuth } from 'contexts/AuthContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Button, HStack } from '@chakra-ui/react';
import CardProfile from '../../../components/common/Cards/CardProfile';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import LogTypes from '../../../config/LogTypes';
import { ROLES } from '../../../config/UserRoles';

export default function EmployeeProfile() {
  const { handleDeleteUser } = useContext(firebaseAuth);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const me = useSelector((state) => state.me);
  const employee = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(actions.employeeGet(id));
    return () => {};
  }, []);

  const handleDelEmployee = async () => {
    const confirm = window.confirm('ยืนยันการลบข้อมูลพนักงาน');
    if (confirm) {
      dispatch(actions.employeeDelete(id)).then(async () => {
        alert('ลบสำเร็จ');
        history.push('/hrms/dashboard');
      });
    }
  };

  const handleInActivateUser = async () => {
    const confirm = window.confirm(
      'ยืนยันการปรับสถานะผู้ใช้งานเป็น Inactivate',
    );
    if (confirm) {
      dispatch(
        actions.logCreate(
          me.employee_id,
          LogTypes.USER_EDIT,
          `ปรับสถานะผู้ใช้งานเป็น In Active `,
        ),
      );
      dispatch(
        actions.employeeEdit(id, { ...employee, employee_verified: false }),
      ).then(async () => {
        if (me.employee_role === ROLES.SUPER_USER)
          dispatch(actions.employeeAll()).then(() => {
            history.push('/hrms/dashboard');
          });
        else
          dispatch(actions.employeeAllFromDepartment(me.department_id)).then(
            () => {
              history.push('/hrms/dashboard');
            },
          );
        alert('ดำเนินการสำเร็จ');
      });
    }
  };

  const handleActivateUser = async () => {
    const confirm = window.confirm('ยืนยันการรับรองผู้ใช้งานคนนี้');
    dispatch(
      actions.logCreate(me.employee_id, LogTypes.USER_EDIT, `รับรองผู้ใช้งาน `),
    );
    if (confirm) {
      dispatch(
        actions.employeeEdit(id, { ...employee, employee_verified: true }),
      ).then(async () => {
        alert('ดำเนินการสำเร็จ');
        if (me.employee_role === ROLES.SUPER_USER)
          dispatch(actions.employeeAll()).then(() => {
            history.push('/hrms/dashboard');
          });
        else
          dispatch(actions.employeeAllFromDepartment(me.department_id)).then(
            () => {
              history.push('/hrms/dashboard');
            },
          );
      });
    }
  };

  return employee.isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        พนักงานทั้งหมด
      </h2>

      <div className="flex py-6 justify-between">
        <div>
          <Link to="/hrms/dashboard">
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div>
          <HStack spacing={2}>
            <Box>
              <Link to={`/hrms/employee/edit/${id}`}>
                {' '}
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>
            {employee.employee_verified === true ? (
              <Button colorScheme="blue" onClick={() => handleInActivateUser()}>
                พักงาน
              </Button>
            ) : (
              <Button colorScheme="green" onClick={() => handleActivateUser()}>
                รับรอง
              </Button>
            )}

            <Button
              colorScheme="red"
              onClick={() => {
                handleDelEmployee();
              }}
            >
              ลบ
            </Button>
          </HStack>
        </div>
      </div>
      <div className="p-4 ">
        <CardProfile
          first_name={employee.employee_first_name}
          last_name={employee.employee_last_name}
          department={employee.position?.department.department_name}
          position={employee.position?.position_name}
          email={employee.employee_email}
          phone_number={employee.employee_phone_number}
          address={employee.employee_address}
          postcode={employee.employee_postcode}
          image={employee.employee_image}
          id={employee.employee_id}
          verified_status={employee.employee_verified}
        />
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
