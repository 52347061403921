/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../../../redux/actions';
import SpinnerLoading from '../../../../../components/Loading/SpinnerLoading';
import CardEditableCardData from '../../../../../components/common/Cards/Task/CardEditableTaskData';

export default function MobileViewAssigning() {
  const allTask = useSelector((state) => state.task);
  const me = useSelector((state) => state.me);
  const [isLoading, setIsLoading] = useState(false);
  const [taskArray, setTaskArray] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.taskAllMyAssign(me.employee_id));
  }, []);

  useEffect(() => {
    if (allTask.isLoading === true) {
      setTaskArray(allTask.arr);
      setIsLoading(true);
    }
    return () => {};
  }, [allTask]);

  const handleDeleteTask = (taskId) => {
    const confirm = window.confirm('ยืนยันการลบงานนี้');
    if (confirm) {
      dispatch(actions.taskDelete(taskId)).then(() => {
        dispatch(actions.taskAllMyAssign(me.employee_id));
      });
    }
  };

  return isLoading ? (
    <div>
      <div className="py-4 w-full">
        {_.map(
          _.orderBy(taskArray, 'task_issue_date', 'desc'),
          (task, index) => (
            <CardEditableCardData
              selectedTask={task}
              key={index}
              handleDelete={handleDeleteTask}
            />
          ),
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
