import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';

// components
import EmployeeLists from './components/EmployeeLists';
import { Button } from '@chakra-ui/react';
import EmployeeSearch from 'components/Search/EmployeeSearch';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import { ROLES } from '../../../config/UserRoles';
import _ from 'lodash';

export default function Dashboard() {
  const me = useSelector((state) => state.me);
  const employee = useSelector((state) => state.employee);
  const [employee_data, setEmployee] = useState(employee.arr);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Admin ก็จัดการ User ในแผนกตัวเองได้ แต่ทำได้แค่ในแผนก
    console.log('On HRMS Dashboard ');
    if (me.employee_role === ROLES.SUPER_USER) {
      console.log('Detect as SUPERUSER ');
      dispatch(actions.employeeAll()).then(() => {
        setIsLoading(true);
      });
    } else {
      dispatch(actions.employeeAllFromDepartment(me.department_id)).then(() => {
        setIsLoading(true);
      });
    }
    return () => {};
  }, []);

  useEffect(() => {
    setEmployee(employee.arr);
    return () => {};
  }, [employee]);

  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        พนักงานทั้งหมด
      </h2>
      <div className="flex justify-between py-6">
        <Link to={'/hrms/employee/create'}>
          <Button colorScheme="blue" variant="solid">
            เพิ่ม
          </Button>
        </Link>
        <EmployeeSearch
          data={employee.arr} //ข้อมูลทั้งหมด
          setData={setEmployee} // เซ็ต temp data ให้เหลือเท่าที่กรอง
        />
      </div>
      <div className="py-4 w-full">
        {_.size(employee_data) ? (
          <div>
            {' '}
            <EmployeeLists employee={employee_data} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
