import React from 'react';
import { Progress } from '@chakra-ui/react';
import _ from 'lodash';

import { TASK_STATUS } from '../../../../../config/TaskStatus';

export default function TaskProgressAnalysis({ taskArray }) {
  return (
    <div className="my-2">
      <div className="flex w-full">
        <div className="w-1/2 md:w-1/6">
          <p className="text-sm">ไม่มีผู้รับ</p>
          <div className="flex">
            <h6>
              {_.size(
                _.filter(
                  taskArray,
                  (task) => task.task_status === TASK_STATUS.INITIAL,
                ),
              )}{' '}
              <span className="text-xs"> งาน </span>
            </h6>{' '}
            <Progress
              colorScheme="cyan"
              size="sm"
              className="w-1/4 self-center mx-2"
              value={
                (_.size(
                  _.filter(
                    taskArray,
                    (task) => task.task_status === TASK_STATUS.INITIAL,
                  ),
                ) /
                  _.size(taskArray)) *
                100
              }
            />
          </div>
        </div>
        <div className="w-1/2 md:w-1/6">
          <p className="text-sm"> ดำเนินการ</p>
          <div className="flex">
            <h6>
              {_.size(
                _.union(
                  _.filter(
                    taskArray,
                    (task) => task.task_status === TASK_STATUS.ACCEPT,
                  ),
                  _.filter(
                    taskArray,
                    (task) => task.task_status === TASK_STATUS.IN_PROGRESS,
                  ),
                ),
              )}{' '}
              <span className="text-xs"> งาน </span>
            </h6>
            <Progress
              colorScheme="cyan"
              size="sm"
              className="w-1/4 self-center mx-2"
              value={
                (_.size(
                  _.union(
                    _.filter(
                      taskArray,
                      (task) => task.task_status === TASK_STATUS.ACCEPT,
                    ),
                    _.filter(
                      taskArray,
                      (task) => task.task_status === TASK_STATUS.IN_PROGRESS,
                    ),
                  ),
                ) /
                  _.size(taskArray)) *
                100
              }
            />
          </div>
        </div>
        <div className="w-1/2 md:w-1/6">
          <p className="text-sm"> สำเร็จ</p>
          <div className="flex">
            <h6>
              {_.size(
                _.filter(
                  taskArray,
                  (task) => task.task_status === TASK_STATUS.SUCCESS,
                ),
              )}{' '}
              <span className="text-xs"> งาน </span>
            </h6>
            <Progress
              colorScheme="cyan"
              size="sm"
              className="w-1/4 self-center mx-2"
              value={
                (_.size(
                  _.filter(
                    taskArray,
                    (task) => task.task_status === TASK_STATUS.SUCCESS,
                  ),
                ) /
                  _.size(taskArray)) *
                100
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
