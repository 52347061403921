import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import CardEachEmployeeStatistic from '../../../../components/common/Cards/Employee/CardEachEmployeeStatistic';

export default function DepartmentPercapitaDashboard() {
  // Variable Define
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const task = useSelector((state) => state.task);
  const employee = useSelector((state) => state.employee);
  const [taskByPerson, setTaskByPerson] = useState();

  // Get Selected Task Data
  useEffect(() => {
    dispatch(actions.employeeAllFromDepartment(params.id)).then(() => {
      dispatch(actions.taskAllFromDepartment(params.id)).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, [params]);

  useEffect(() => {
    if (task.arr) {
      const tempTaskByPerson = _.groupBy(task.arr, 'task_acceptance');
      setTaskByPerson(tempTaskByPerson);
      console.log('Task By Person', tempTaskByPerson);
    }
    return () => {};
  }, [task]);

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1">
          สถิติการทำงานของแต่ละคน
        </h2>

        <div className="py-4 md:py-6">
          <Button
            variant="outline"
            onClick={() => {
              history.push('/');
            }}
          >
            กลับ
          </Button>
        </div>
        <div className="p-4">
          {_.map(employee.arr, (eachEmployee, index) => (
            <CardEachEmployeeStatistic
              employee={eachEmployee}
              allTask={taskByPerson[eachEmployee.employee_id]}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }
  return <Spinners />;
}
