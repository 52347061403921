import axios from 'axios';
import { INFORMATION_GET, INFORMATION_PUT } from '../types';

export const informationGet = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/information/')
      .then((res) => {
        if (res.data) {
          dispatch({ type: INFORMATION_GET, payload: res.data });
        }
      })
      .catch((e) => {
        dispatch({ type: INFORMATION_GET, payload: { error: e?.message } });
      });
  };
};

export const informationPut = (id, data) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + '/information/' + id, data)
      .then((res) => {
        if (res.data) {
          dispatch({ type: INFORMATION_PUT, payload: res.data });
        }
      })
      .catch((e) => {
        dispatch({ type: INFORMATION_PUT, payload: { error: e?.message } });
      });
  };
};
