// สร้างพื้นที่สำหรับเก็บ Element ของตาราง
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import TableListComponent from '../Components/TableListComponent';
import TableAcceptableListComponent from '../Components/TableListAcceptableComponent';
import * as actions from '../../../redux/actions';

// ลิงค์ฟังก์ชั้น handleDeleteTask มาจากหน้าที่แล้ว และ ส่งต่อไปยังอีกหน้านึง
// ที่ส่งค่าไปอัพเดทที่ component ใหญ่ เพราะกลัวว่ามันจะกระทบตัวอื่น
// แต่จริง ๆ ไม่น่าเป็นอะไร สามารถเรียกใช้ใน ListComponent เลยก็ได้

export default function TableContainerNoFilter({
  taskArray,
  acceptable = false,
  onUpdateData,
}) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const [isLoading, setIsloading] = useState(false);
  const acceptWorkAssignment = (taskId, taskData) => {
    // eslint-disable-next-line no-undef
    const confirm = window.confirm('ยืนยันการรับงาน');
    if (confirm) {
      setIsloading(false);
      dispatch(actions.taskAccept(taskId, me.employee_id)).then(() => {
        dispatch(
          actions.taskNotifyDirecly(
            taskData.task_issuer,
            'มีผู้รับงานของคุณแล้ว',
            `${me.employee_first_name} ได้รับงาน ${taskData.task_title} ของคุณแล้ว`,
          ),
        ).then(() => {
          // It will directly go into task data page because Link tag is over that
          onUpdateData();
        });
      });
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" my-4 mx-4 overflow-x-auto">
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>หัวข้องาน</Th>
                  <Th>วันที่แจ้ง</Th>
                  <Th>วันที่สำเร็จ</Th>
                  <Th>แผนกที่ทำงาน</Th>
                  <Th>สถานะ</Th>
                  <Th>ผู้รับงาน</Th>
                </Tr>
              </Thead>
              <Tbody>
                {taskArray && _.isArray(taskArray) && !_.isEmpty(taskArray) ? (
                  _.map(
                    _.orderBy(taskArray, 'task_status', 'desc'),
                    (task, index) => {
                      if (acceptable === false)
                        return (
                          <TableListComponent
                            index={index}
                            taskData={task}
                            key={index}
                          />
                        );
                      else if (acceptable === true)
                        return (
                          <TableAcceptableListComponent
                            index={index}
                            taskData={task}
                            key={index}
                            onAcceptWorkAssignment={acceptWorkAssignment}
                          />
                        );
                    },
                  )
                ) : (
                  <></>
                )}
              </Tbody>
            </Table>
            {taskArray && _.isArray(taskArray) && !_.isEmpty(taskArray) ? (
              <></>
            ) : (
              <p className="text-center my-4"> ยังไม่มีรายการสั่งงาน</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
