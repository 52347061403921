import {
  TASK_ALL,
  TASK_POST,
  TASK_GET,
  TASK_DELETE,
  TASK_PUT,
  TASK_UNSUCCESS,
  TASK_UNACCEPT,
  TASK_INPROGRESS,
  TASK_SUCCESS,
  TASK_MY_ASSIGN,
  TASK_ASSIGN_ME,
} from '../../actions/types';

// As same as Assigning Reducer
const initialState = {
  task: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TASK_ALL:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };
    case TASK_UNSUCCESS:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };
    case TASK_UNACCEPT:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };
    case TASK_INPROGRESS:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };
    case TASK_SUCCESS:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };

    case TASK_MY_ASSIGN:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };
    case TASK_ASSIGN_ME:
      return {
        arr: action?.payload?.arr,
        currentPage: action?.payload.currentPage,
        allSize: action?.payload?.allSize,
        sizeOfPage: action?.payload?.sizeOfPage,
        isLoading: true,
      };
    case TASK_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case TASK_POST:
      return action.payload;
    case TASK_PUT:
      return action.payload;
    case TASK_DELETE:
      return { isLoading: true };
    default:
      return state;
  }
}
