/*eslint-disable*/
import React, { useContext } from 'react';
import { firebaseAuth } from 'contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NotificationDropdown from 'components/common/Dropdowns/NotificationDropdown.js';
import UserDropdown from 'components/common/Dropdowns/UserDropdown';
import AppLogo from '../../../assets/img/seaseekerlogo-removebg.png';
import { ROLES } from '../../../config/UserRoles';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const { handleSignout } = useContext(firebaseAuth);
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  return (
    <>
      <nav className="print-disable  md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-1 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          {/* <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <i className="fas fa-bars"></i>
          </button> */}
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            <div className="flex gap-2">
              <img src={information?.system_logo} className="h-10" />
              <span className="self-center">
                {information?.system_name || 'e-WMS'}
              </span>
            </div>
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            {/* <li className="inline-block relative">
              <NotificationDropdown />
            </li> */}
            <li className="inline-block relative">{/* <UserDropdown /> */}</li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    <div className="flex gap-2">
                      <img src={information?.system_logo} className="h-10" />
                      <span className="self-center">
                        {information?.system_name || 'e-WMS'}
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              จัดการระบบ
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/hrms/dashboard') !== -1 &&
                    _.last(_.split(window.location.href, '/hrms/dashboard')) ===
                      ''
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/hrms/dashboard"
                >
                  <i
                    className={
                      'fas fa-users mr-2 text-sm ' +
                      (window.location.href.indexOf('/hrms/dashboard') !== -1 &&
                      _.last(
                        _.split(window.location.href, '/hrms/dashboard'),
                      ) === ''
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  พนักงาน
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/hrms/department') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/hrms/department"
                >
                  <i
                    className={
                      'fas  fa-building mr-2 text-sm ' +
                      (window.location.href.indexOf('/hrms/department') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  แผนก
                </Link>
              </li>
              {me && me.employee_role === ROLES.SUPER_USER && (
                <li className="items-center">
                  <Link
                    className={
                      'text-xs uppercase py-3 font-bold block ' +
                      (window.location.href.indexOf('/hrms/workstation') !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                    }
                    to="/hrms/workstation"
                  >
                    <i
                      className={
                        'fas  fa-map-marker mr-2 text-sm ' +
                        (window.location.href.indexOf('/hrms/workstation') !==
                        -1
                          ? 'opacity-75'
                          : 'text-gray-400')
                      }
                    ></i>{' '}
                    จัดการสถานีงาน
                  </Link>
                </li>
              )}
              {me && me.employee_role === ROLES.SUPER_USER && (
                <li className="items-center">
                  <Link
                    className={
                      'text-xs uppercase py-3 font-bold block ' +
                      (window.location.href.indexOf('/hrms/management') !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                    }
                    to="/hrms/management"
                  >
                    <i
                      className={
                        'fas  fa-user-cog mr-2 text-sm ' +
                        (window.location.href.indexOf('/hrms/management') !== -1
                          ? 'opacity-75'
                          : 'text-gray-400')
                      }
                    ></i>{' '}
                    จัดการผู้ใช้
                  </Link>
                </li>
              )}

              {me && me.employee_role === ROLES.SUPER_USER && (
                <li className="items-center">
                  <Link
                    className={
                      'text-xs uppercase py-3 font-bold block ' +
                      (window.location.href.indexOf('/hrms/logs') !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                    }
                    to="/hrms/logs"
                  >
                    <i
                      className={
                        'fas  fa-database mr-2 text-sm ' +
                        (window.location.href.indexOf('/hrms/logs') !== -1
                          ? 'opacity-75'
                          : 'text-gray-400')
                      }
                    ></i>{' '}
                    ดูประวัติการใช้งาน
                  </Link>
                </li>
              )}

              {me && me.employee_role === ROLES.SUPER_USER && (
                <li className="items-center">
                  <Link
                    className={
                      'text-xs uppercase py-3 font-bold block ' +
                      (window.location.href.indexOf('/hrms/information') !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                    }
                    to="/hrms/information"
                  >
                    <i
                      className={
                        'fas  fa-sliders-h mr-2 text-sm ' +
                        (window.location.href.indexOf('/hrms/information') !==
                        -1
                          ? 'opacity-75'
                          : 'text-gray-400')
                      }
                    ></i>{' '}
                    ตั้งค่าข้อมูลระบบ
                  </Link>
                </li>
              )}
            </ul>

            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              {me?.employee_role === ROLES.ADMIN ||
              me?.employee_role === ROLES.SUPER_USER ? (
                <>
                  <Link to="/">
                    <li className="items-center">
                      <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer">
                        <i className="fas fa-cog text-gray-500 mr-2 text-sm"></i>{' '}
                        จัดการระบบ
                      </div>
                    </li>
                  </Link>
                </>
              ) : (
                <></>
              )}
              <li className="items-center">
                <div
                  className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                  onClick={() => {
                    onSignOut();
                  }}
                >
                  <i className="fas fa-door-open text-gray-500 mr-2 text-sm"></i>{' '}
                  Sign Out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
