import React, { useState, useEffect } from 'react';
import { authMethods } from '../config/firebase/authMethods';

const AuthProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);
  const [token, setToken] = useState(null);
  useEffect(() => {
    return () => {
      setErrors([]);
      setToken(null);
    };
  }, []);
  const handleSignup = async (email, password, data) => {
    console.log('[AuthContext]  กำลังลงทะเบียน');
    await authMethods.signup(email, password, data, setErrors, setToken);
  };
  const handleSignupByHRMS = async (email, password, data) => {
    console.log('[AuthContext]  กำลังลงทะเบียน');
    await authMethods.signupByHRMS(email, password, data, setErrors, setToken);
  };
  const handleSignin = async (email, password) => {
    console.log('[AuthContext] กำลังเข้าสู่ระบบ');
    await authMethods.signin(email, password, setErrors, setToken);
  };
  const handleSignout = async () => {
    console.log('[AuthContext] กำลังลงชื่อออก');
    await authMethods.signout(setErrors, setToken);
  };
  const handleForgottenPassword = async email => {
    console.log('[AuthContext] กำลังยืนยันการลืมรหัสผ่าน');
    await authMethods.forgotenPassword(email, setErrors, setToken);
  };
  const handleProviderAuth = () => {
    return authMethods.siginWithProvider(setToken);
  };
  const handleDeleteUser = async uid => {
    console.log('[AuthContext] กำลังลบผู้ใช้จาก firebase ');
    await authMethods.deleteUser(uid);
  };
  return (
    <firebaseAuth.Provider
      value={{
        token,
        handleSignin,
        handleProviderAuth,
        handleSignup,
        handleSignupByHRMS,
        handleSignout,
        handleForgottenPassword,
        handleDeleteUser,
        errors,
      }}
    >
      {children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
export const firebaseAuth = React.createContext();
