import axios from "axios";
import { ME_GET, ME_RESET } from "../types";
import { setStorage, removeStorage } from "util/localstorage";

export const meGet = (payload) => {
  return async (dispatch) => {
    const uid = window.localStorage.me;
    await axios
      .get(process.env.REACT_APP_API_URL + "/me/" + uid)
      .then((res) => {
        if (res.data) {
          dispatch({ type: ME_GET, payload: res.data });
        }
      })
      .catch((e) => {
        console.log(e);
        removeStorage("token");
        localStorage.removeItem("me");
      });
  };
};

export const meReset = () => {
  return async (dispatch) => {
    await dispatch({ type: ME_RESET, payload: null });
  };
};
