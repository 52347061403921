import React from 'react';
import _ from 'lodash';

import PersonalTaskProgressAnalysis from './Analysis/PersonalTaskProgressAnalysis';
import TaskAverageTime from './Analysis/TaskAverageTime';
import TaskAssignerAnalysis from './Analysis/TaskAssignerAnalysis';

export default function CardPersonalTaskAnalysis({
  taskArray,
  commonWorkDatas,
  allTaskSize = 0,
}) {
  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\  p-2 m-2 flex flex-wrap">
          <div className="w-full lg:w-1/2">
            <div>
              {commonWorkDatas ? (
                <h3>{commonWorkDatas.work_name} </h3>
              ) : (
                <h3>อื่น ๆ</h3>
              )}
            </div>
            <div className="flex flex-wrap">
              <div className="md:w-1/4">
                <div className="flex gap-1">
                  <h2 className="text-3xl"> {_.size(taskArray)} </h2>
                  <h6 className="text-sm self-end">/ {allTaskSize} งาน</h6>
                </div>
              </div>
            </div>
            <PersonalTaskProgressAnalysis taskArray={taskArray} />{' '}
          </div>

          <div className="lg:w-1/4">
            <TaskAverageTime taskArray={taskArray} />
          </div>
          <div className="lg:w-1/4 ml-4 lg:ml-0">
            <TaskAssignerAnalysis allTask={taskArray} />
          </div>
        </div>
      </div>
    </div>
  );
}
