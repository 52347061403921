import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'redux/actions/index';

import DepartmentLists from '../../../components/Department/DepartmentLists';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ROLES } from '../../../config/UserRoles';

export default function Department() {
  const dispatch = useDispatch();

  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);
  const [filteredDepartment, setFilteredDepartment] = useState();

  useEffect(() => {
    dispatch(actions.departmentAll());
    return () => {};
  }, []);

  useEffect(() => {
    if (me.employee_role === ROLES.SUPER_USER) {
      setFilteredDepartment(department);
    } else {
      const tempFilteredDepartment = _.filter(
        department,
        (depart) => depart.department_id === me.department_id,
      );
      setFilteredDepartment(tempFilteredDepartment);
    }
  }, [department]);

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">แผนกทั้งหมด</h2>

      <div className="py-6">
        <Link to="/hrms/department/create">
          <Button
            colorScheme="blue"
            disabled={me.employee_role === ROLES.SUPER_USER ? false : true}
          >
            เพิ่ม
          </Button>
        </Link>
      </div>
      <div>
        {_.size(filteredDepartment) ? (
          <div>
            {' '}
            <DepartmentLists department={filteredDepartment} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  );
}
