import { storage } from '../config/firebase/firebase-client';

// delay เพื่อรอการค้นหารูปบน firebase
function delay(t, v) {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null, v), t);
  });
}

// ให้วนหารูปภาพจนกว่าจะได้ 10 รอบ
function keepTrying(triesRemaining, storageRef) {
  if (triesRemaining < 0) {
    return Promise.reject('out of tries');
  }
  return storageRef
    .getDownloadURL()
    .then((url) => url)
    .catch((error) => {
      switch (error.code) {
        case 'storage/object-not-found':
          return delay(2000).then(() =>
            keepTrying(triesRemaining - 1, storageRef),
          );
        default:
          console.log(error);
          return Promise.reject(error);
      }
    });
}

export const uploadImageFile = (fileName, file) =>
  new Promise((resolve, reject) => {
    const uploadTask = storage
      .ref(`${process.env.REACT_APP_NAME}/${fileName}`)
      .putString(file, 'data_url'); // data_url

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`${percent}% done`);
        // progressVisible(percent);
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      (complete) => {
        console.log('upload file complete!');

        const resizeFileName = `${fileName}_${process.env.REACT_APP_RESIZE_IMG}`;
        const storageRef = storage
          .ref(`${process.env.REACT_APP_NAME}/resized`)
          .child(resizeFileName);
        keepTrying(10, storageRef).then((url) => {
          console.log('get base64 resize file complete!');
          resolve(url);
        });
      },
    );
  });

export const getBase64ImageFromURL = (url) => {
  console.log('URL : ', url);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/jpeg');
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
