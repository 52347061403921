import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

import { TASK_STATUS } from '../../config/TaskStatus';

export default function TaskByStatusGraph({ allTask }) {
  const [dataStack, setDataStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  // Format Data
  useEffect(() => {
    // Find Task Amount
    let acceptCount = 0;
    let inProgressCount = 0;
    let initialCount = 0;

    _.map(allTask, (task) => {
      if (task.task_status === TASK_STATUS.INITIAL) {
        initialCount++;
      } else if (task.task_status === TASK_STATUS.ACCEPT) {
        acceptCount++;
      } else if (task.task_status === TASK_STATUS.IN_PROGRESS) {
        inProgressCount++;
      }
    });

    // setting new dataStack into the state
    const tempDataStack = [
      {
        name: 'จำนวนงาน',
        data: [initialCount, acceptCount, inProgressCount],
      },
    ];

    const tempLabelStack = [
      TASK_STATUS.INITIAL,
      TASK_STATUS.ACCEPT,
      TASK_STATUS.IN_PROGRESS,
    ];

    setDataStack(tempDataStack);
    setLabelStack(tempLabelStack);

    return () => {};
  }, [allTask]);

  const options = {
    chart: {
      type: 'bar',
    },
    labels: labelStack,
  };
  return (
    <div>
      <ReactApexChart
        options={options}
        series={dataStack}
        type="bar"
        height="400"
      />
    </div>
  );
}
