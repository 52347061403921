import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

// components

import AdminNavbar from 'components/common/Navbars/AdminNavbar.js';
import Sidebar from 'components/common/Sidebar/Sidebar-HRMS';
import HeaderStats from 'components/common/Headers/HeaderStats.js';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views

import Dashboard from '../views/hrms/dashboard/Dashboard';
import EmployeeProfile from '../views/hrms/employeeProfile/EmployeeProfile';
import CreateEmployee from '../views/hrms/createEmployee/CreateEmployee';
import EditEmployee from '../views/hrms/editEmployee/EditEmployee';

import Department from '../views/hrms/department/Department';
import DepartmentDepartment from '../views/hrms/detailDepartment/DetailDepartment';
import CreateDepartment from '../views/hrms/createDepartment/CreateDepartment';
import EditDepartment from '../views/hrms/editDepartment/EditDepartment';

import CreatePosition from '../views/hrms/createPosition/CreatePosition';
import EditPosition from '../views/hrms/editPosition/EditPosition';

import ManagmentUser from '../views/hrms/managmentUser/ManagmentUser';
import CreateCommonWork from '../views/hrms/createCommonWork/CreateCommonWork';
import EditCommonWork from '../views/hrms/editCommonWork/EditCommonWork';

import SystemLogViewers from '../views/hrms/systemLogs/SystemLogs';
import ManagementWorkstation from '../views/hrms/workstations/ManagementWorkStation';
import CreateWorkStation from '../views/hrms/workstations/CreateWorkStation';
import EditWorkStation from '../views/hrms/workstations/EditWorkStation';

import ManagementSystemInfo from '../views/hrms/systemInformation/ManagementSystemInformation';

// Enumeration
import { Spinner } from '@chakra-ui/react';
import { ROLES } from '../config/UserRoles';
import * as actions from '../redux/actions';
import BottomNavigation from '../components/common/Navbars/BottomNav/HRMSNav';

export default function HRMS() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const me = useSelector((state) => state.me);

  // ข้อมูลสำหรับ authorize จาก  local_storage
  // eslint-disable-next-line no-undef
  const storageRemember = localStorage.getItem('me');

  useEffect(() => {
    console.log('WMS-Dashboard Module is Active');
    dispatch(actions.meGet(storageRemember));
    dispatch(actions.informationGet());

    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me) {
      if (
        me.employee_role === ROLES.SUPER_USER ||
        me.employee_role === ROLES.ADMIN
      ) {
        console.log('User is Allow');
        setIsLoading(true);
      } else {
        history.push('/');
      }
    }

    return () => {};
  }, [me]);

  if (me) {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-gray-200">
          <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
            <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-screen">
              <div className="rounded-t mb-0 px-4 pt-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-gray-500 text-xs font-semibold">
                      จัดการระบบ
                    </h6>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <Switch>
                  <Route path="/hrms/dashboard" exact component={Dashboard} />
                  <Route
                    path="/hrms/employee/profile/:id"
                    exact
                    component={EmployeeProfile}
                  />
                  <Route
                    path="/hrms/employee/create"
                    exact
                    component={CreateEmployee}
                  />
                  <Route
                    path="/hrms/employee/edit/:id"
                    exact
                    component={EditEmployee}
                  />

                  <Route path="/hrms/department" exact component={Department} />
                  <Route
                    path="/hrms/department/details/:id"
                    exact
                    component={DepartmentDepartment}
                  />

                  <Route
                    path="/hrms/department/edit/:id"
                    exact
                    component={EditDepartment}
                  />
                  <Route
                    path="/hrms/department/:id/position/create"
                    exact
                    component={CreatePosition}
                  />
                  <Route
                    path="/hrms/department/:id/commonwork/create"
                    exact
                    component={CreateCommonWork}
                  />
                  <Route
                    path="/hrms/department/:id/position/edit/:id2"
                    exact
                    component={EditPosition}
                  />

                  <Route
                    path="/hrms/department/:id/commonwork/edit/:id2"
                    exact
                    component={EditCommonWork}
                  />

                  {/** สำหรับ SUPER USER เท่านั้น */}
                  {me.employee_role === ROLES.SUPER_USER && (
                    <>
                      <Route
                        path="/hrms/department/create"
                        exact
                        component={CreateDepartment}
                      />

                      <Route
                        path="/hrms/workstation/create"
                        exact
                        component={CreateWorkStation}
                      />

                      <Route
                        path="/hrms/workstation/edit/:id"
                        exact
                        component={EditWorkStation}
                      />

                      <Route
                        path="/hrms/workstation"
                        exact
                        component={ManagementWorkstation}
                      />

                      <Route
                        path="/hrms/management"
                        exact
                        component={ManagmentUser}
                      />
                      <Route
                        path="/hrms/logs"
                        exact
                        component={SystemLogViewers}
                      />
                      <Route
                        path="/hrms/information"
                        exact
                        component={ManagementSystemInfo}
                      />
                    </>
                  )}

                  <Redirect from="/hrms" to="/hrms/dashboard" />
                </Switch>
              </div>
              <div />
            </div>
          </div>
          <BottomNavigation />
          <FooterAdmin />
        </div>
      </>
    );
  }

  return <Spinner />;
}
