import { combineReducers } from 'redux';
// Common Module
import MeReducers from './common/MeReducers';
import InformationReducers from './common/InformationReducers';
// HRMS Module
import EmployeeReducers from './hrms/EmployeeReducer';
import DepartmentReducers from './hrms/DepartmentReducer';
import PositionReducers from './hrms/PositionReducer';

// WMS Module
import TaskReducers from './wms/TaskReducers';
import CommentReducers from './wms/CommentReducers';
import CommonWorkReducer from './hrms/CommonWorkReducer';
import NotifyReducer from './wms/NotifyReducers';
import LogReducers from './wms/LogReducers';
import WorkstationReducer from './hrms/WorkStationReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  employee: EmployeeReducers,
  department: DepartmentReducers,
  position: PositionReducers,
  task: TaskReducers,
  comment: CommentReducers,
  commonWork: CommonWorkReducer,
  notify: NotifyReducer,
  log: LogReducers,
  workstation: WorkstationReducer,
  information: InformationReducers,
});

export default rootRuducer;
