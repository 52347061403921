import React from 'react';
import { useSelector } from 'react-redux';

export default function Footer() {
  const information = useSelector((state) => state.information);
  return (
    <>
      <footer className=" bg-gray-300  pb-4">
        <div className="container mx-auto px-4">
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-600 font-semibold py-1">
                Copyright © {new Date().getFullYear()}{' '}
                <a
                  href={information?.system_url}
                  className="text-gray-600 hover:text-gray-900"
                >
                  {information?.system_owner_name}
                </a>
                <br />
                <a
                  href={information?.system_sponsor?.url}
                  className="text-gray-600 hover:text-gray-900"
                >
                  {information?.system_sponsor}
                </a>{' '}
                ,{' '}
                <a
                  href="https://iarc.psu.ac.th"
                  className="text-gray-600 hover:text-gray-900"
                >
                  IARC PSU
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
