import axios from 'axios';
import {
  EMPLOYEE_ALL,
  EMPLOYEE_ALL_BY_DELIVERY,
  EMPLOYEE_GET,
  EMPLOYEE_PUT,
  EMPLOYEE_DEL,
  EMPLOYEE_ALL_BY_DEPARTMENT,
} from '../types';

export const employeeAll = () => async (dispatch) => {
  await axios.get(`${process.env.REACT_APP_API_URL}/employee`).then((res) => {
    console.log('Request Server to Get All Employees');
    dispatch({ type: EMPLOYEE_ALL, payload: res.data });
  });
};

export const employeeAllFromDepartment = (departmentId) => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/employee/department/${departmentId}`)
    .then((res) => {
      console.log('Request Server to Get All Employees in Department');
      dispatch({ type: EMPLOYEE_ALL_BY_DEPARTMENT, payload: res.data });
    });
};

export const employeeAllByDelivery = (payload) => async (dispatch) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/employee/by/delivery`, payload)
    .then((res) => {
      console.log('Request Server to Get  All By Delivery Employees');
      dispatch({ type: EMPLOYEE_ALL_BY_DELIVERY, payload: res.data });
    });
};

export const employeeGet = (payload) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/employee/${payload}`)
    .then((res) => {
      console.log('Request Server to Get an Employees');
      if (res.data) {
        dispatch({ type: EMPLOYEE_GET, payload: res.data });
      } else {
        dispatch({ type: EMPLOYEE_GET, payload: null });
      }
    });
};

export const employeeDelete = (payload) => async (dispatch) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/employee/${payload}`)
    .then((res) => {
      console.log('Request Server to Delete an Employees');
      if (res.data) {
        dispatch({ type: EMPLOYEE_DEL, payload: null });
        dispatch(employeeAll());
      }
    });
};

export const employeeEdit = (id, payload) => async (dispatch) => {
  await axios
    .put(`${process.env.REACT_APP_API_URL}/employee/${id}`, payload)
    .then((res) => {
      console.log('Request Server to Edit an Employees');
      if (res.data) {
        dispatch({ type: EMPLOYEE_PUT, payload: res.data });
      }
    });
};
