import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import OverviewChart from './Components/AllWorkGraph';
import TableOverview from '../../../../components/Table/Container/TableContainerWithFilter';

export default function HistoryDashboard() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const task = useSelector((state) => state.task);
  const department = useSelector((state) => state.department);

  // Get Selected Task Data
  useEffect(() => {
    dispatch(actions.departmentAll()).then(() => {
      dispatch(actions.taskAll(1, 10000)).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, []);

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1">
          รายะเอียดงานทั้งหมด
        </h2>

        <div className="py-4 md:py-6">
          <Link to="/">
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div className="p-4">
          <OverviewChart allDepartment={department} allTask={task?.arr} />
        </div>
      </div>
    );
  }
  return <Spinners />;
}
