import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import OverviewChart from './Components/AllWorkGraph';
import TableOverview from '../../../../components/Table/Container/DepartmentTableWithFilter';

export default function DepartmentHistoryTable() {
  // Variable Define
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const task = useSelector((state) => state.task);

  // Get Selected Task Data
  useEffect(() => {
    dispatch(actions.taskAllFromDepartment(params.id, 1, 10000)).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, [params]);

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 print-disable ">
          ตารางการทำงาน
        </h2>

        <div className="py-4 md:py-6 print-disable">
          <Link to="/">
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div className="p-4">
          <TableOverview taskArray={task?.arr} />
        </div>
      </div>
    );
  }
  return <Spinners />;
}
