import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Spinner } from '@chakra-ui/react';
import _, { fromPairs } from 'lodash';

import * as actions from '../../../../redux/actions';
import { DEPART_INFO_TAB } from '../../../../config/WordUtil';
import DepartmentPresentTask from '../../../../components/Department/DepartmentPresentTask';
import TaskStatictic from '../../../../components/Department/TaskStatistic';

export default function DepartmentTask() {
  // Variable Define
  const dispatch = useDispatch();

  // ดึงState Task ทั้งหมดที่ Assign ให้ คนๆ นี้  แต่เนื่องจากมันเป็น state ที่มี arr,isLoading อยู่
  const me = useSelector((state) => state.me);
  const department = useSelector((state) => state.department);
  const task = useSelector((state) => state.task);
  const [currentDepartment, setCurrentDepartment] = useState();
  const [pageTabStatus, setPageTabStatus] = useState(DEPART_INFO_TAB.PRESENT);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  // UI Rendering
  useEffect(() => {
    dispatch(actions.taskAllFromDepartment(me.department_id));
    const tempCurrentDepartment = _.find(
      department,
      (depart) => depart.department_id === me.department_id,
    );
    dispatch(actions.taskAllFromDepartment(me.department_id)).then(() => {
      setIsLoading(true);
    });
    setCurrentDepartment(tempCurrentDepartment);
    return () => {};
  }, [me]);

  if (isLoading)
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 ">
          งานในแผนก
          {currentDepartment ? currentDepartment.department_name : ''}
        </h2>
        <div className="py-1 md:py-6 flex gap-2">
          <Button variant="outline" onClick={() => history.goBack()}>
            กลับ
          </Button>
          <Button
            variant="outline"
            colorScheme={
              pageTabStatus === DEPART_INFO_TAB.PRESENT ? 'blue' : 'gray'
            }
            onClick={() => setPageTabStatus(DEPART_INFO_TAB.PRESENT)}
          >
            สถานะงานตอนนี้
          </Button>
          <Button
            variant="outline"
            colorScheme={
              pageTabStatus === DEPART_INFO_TAB.STATISTIC ? 'blue' : 'gray'
            }
            onClick={() => setPageTabStatus(DEPART_INFO_TAB.STATISTIC)}
          >
            สถิติงาน
          </Button>
        </div>
        <div className="p-4">
          {pageTabStatus === DEPART_INFO_TAB.PRESENT && (
            <DepartmentPresentTask allTask={task.arr} />
          )}
          {pageTabStatus === DEPART_INFO_TAB.STATISTIC && (
            <TaskStatictic allTask={task.arr} departmentId={me.department_id} />
          )}
        </div>
      </div>
    );
  else {
    return <Spinner />;
  }
}
