import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// components
import * as actions from '../../../redux/actions';
import UserLists from './components/UserLists';
import { Button } from '@chakra-ui/react';
import EmployeeSearch from 'components/Search/EmployeeSearch';
import SpinnerLoading from 'components/Loading/SpinnerLoading';

export default function ManagmentUser() {
  const employee = useSelector((state) => state.employee);
  const [employee_data, setEmployee] = useState(employee.arr);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.employeeAll()).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  useEffect(() => {
    setEmployee(employee.arr);
    return () => {};
  }, [employee]);

  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">จัดการผู้ใช้</h2>
      <div className="flex justify-end">
        <EmployeeSearch
          data={employee.arr} //ข้อมูลทั้งหมด
          setData={setEmployee} // เซ็ต temp data ให้เหลือเท่าที่กรอง
        />
      </div>
      <div className="py-4 w-full">
        {_.size(employee_data) ? (
          <div>
            {' '}
            <UserLists employee={employee_data} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
