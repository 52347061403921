import React from 'react';
import _ from 'lodash';
import CardEmployee from '../../../../components/common/Cards/CardArticle';
import { Link } from 'react-router-dom';

export default function EmployeeLists({ employee }) {
  const genCardEmployee = () => {
    return _.map(_.orderBy(employee, 'employee_verified'), (_employee) => (
      <div
        key={_employee.employee_id}
        className="w-full lg:w-3/12 p-2 cursor-pointer"
      >
        <Link to={'/hrms/employee/profile/' + _employee.employee_id}>
          {' '}
          <CardEmployee
            first_name={_employee.employee_first_name}
            last_name={_employee.employee_last_name}
            title={
              _employee.position &&
              'ตำแหน่ง : ' + _employee.position?.position_name
            }
            subtitle={
              _employee.position &&
              'แผนก : ' + _employee.position?.department.department_name
            }
            email={_employee.employee_email}
            phone_number={_employee.employee_phone_number}
            image={_employee.employee_image}
            id={_employee.employee_id}
            verify_status={_employee.employee_verified}
          />
        </Link>
      </div>
    ));
  };
  return (
    <>
      <div className=" flex-auto">
        <div className="relative flex flex-wrap">{genCardEmployee()}</div>
      </div>
    </>
  );
}
