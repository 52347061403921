import React from 'react';
import moment from 'moment';
import 'moment/locale/th';

import { TASK_STATUS as TaskStatus } from '../../../../config/TaskStatus';
// components

export default function CardTaskProgress({ selectedTask }) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" mt-2 ml-4">
            <div className="my-4">
              <div className="flex flex-wrap lg:flex-nowrap ">
                <div className="w-full my-2 lg:w-1/4 text-center px-6">
                  <div
                    className={
                      selectedTask.task_status === TaskStatus.INITIAL ||
                      selectedTask.task_status === TaskStatus.ACCEPT ||
                      selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                      selectedTask.task_status === TaskStatus.SUCCESS
                        ? 'bg-green-300 rounded-lg flex items-center justify-center border border-gray-200'
                        : 'bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200'
                    }
                  >
                    <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                      <i className="fas fa-check" />
                    </div>
                    <div
                      className={
                        selectedTask.task_status === TaskStatus.INITIAL ||
                        selectedTask.task_status === TaskStatus.ACCEPT ||
                        selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                        selectedTask.task_status === TaskStatus.SUCCESS
                          ? 'w-2/3 bg-green-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                          : 'w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                      }
                    >
                      <h2 className="font-bold ">แจ้งงาน</h2>
                      <p className="text-base text-gray-600">
                        {moment(selectedTask.task_issue_date).format('D MMM ')}
                        <br />
                        {moment(selectedTask.task_issue_date).format(
                          'HH.mm น.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex-1 hidden lg:flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
                  </svg>
                </div>
                <div className="w-full my-2 lg:w-1/4 text-center px-6">
                  <div
                    className={
                      selectedTask.task_status === TaskStatus.ACCEPT ||
                      selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                      selectedTask.task_status === TaskStatus.SUCCESS
                        ? 'bg-green-300 rounded-lg flex items-center justify-center border border-gray-200'
                        : 'bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200'
                    }
                  >
                    { /* prettier-ignore */ }
                    <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                      {selectedTask.task_status === TaskStatus.ACCEPT
                      || selectedTask.task_status === TaskStatus.IN_PROGRESS
                      || selectedTask.task_status === TaskStatus.SUCCESS ? (
                        <i className="fas fa-check" />
                        ) : (
                          <i className="fas fa-times" />
                        )}
                    </div>

                    <div
                      className={
                        selectedTask.task_status === TaskStatus.ACCEPT ||
                        selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                        selectedTask.task_status === TaskStatus.SUCCESS
                          ? 'w-2/3 bg-green-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                          : 'w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                      }
                    >
                      <h2 className="font-bold">รับงาน</h2>
                      <p className="text-base text-gray-600">
                        {selectedTask.task_accept_date ? (
                          <span>
                            {moment(selectedTask.task_accept_date).format(
                              'D MMM ',
                            )}
                            <br />
                            {moment(selectedTask.task_accept_date).format(
                              'HH.mm น.',
                            )}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-1 hidden lg:flex  items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
                  </svg>
                </div>
                <div className="w-full my-2 lg:w-1/4 text-center px-6">
                  <div
                    className={
                      selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                      selectedTask.task_status === TaskStatus.SUCCESS
                        ? 'bg-green-300 rounded-lg flex items-center justify-center border border-gray-200'
                        : 'bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200'
                    }
                  >
                    <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                      {selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                      selectedTask.task_status === TaskStatus.SUCCESS ? (
                        <i className="fas fa-check" />
                      ) : (
                        <i className="fas fa-times" />
                      )}
                    </div>
                    <div
                      className={
                        selectedTask.task_status === TaskStatus.IN_PROGRESS ||
                        selectedTask.task_status === TaskStatus.SUCCESS
                          ? 'w-2/3 bg-green-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                          : 'w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                      }
                    >
                      <h2 className="font-bold ">เริ่มงาน</h2>
                      <p className="text-base text-gray-600">
                        {selectedTask.task_startprogress_date ? (
                          <span>
                            {moment(
                              selectedTask.task_startprogress_date,
                            ).format('D MMM ')}

                            <br />
                            {moment(
                              selectedTask.task_startprogress_date,
                            ).format('HH.mm น.')}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-1 hidden lg:flex  items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z" />
                  </svg>
                </div>
                <div className="w-full my-2 lg:w-1/4 text-center px-6">
                  <div
                    className={
                      selectedTask.task_status === TaskStatus.SUCCESS
                        ? 'bg-green-300 rounded-lg flex items-center justify-center border border-gray-200'
                        : 'bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200'
                    }
                  >
                    <div className="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step">
                      {selectedTask.task_status === TaskStatus.SUCCESS ? (
                        <i className="fas fa-check" />
                      ) : (
                        <i className="fas fa-times" />
                      )}
                    </div>
                    <div
                      className={
                        selectedTask.task_status === TaskStatus.SUCCESS
                          ? 'w-2/3 bg-green-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                          : 'w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step'
                      }
                    >
                      <h2 className="font-bold ">ปิดงาน</h2>
                      <p className="text-base text-gray-600">
                        {selectedTask.task_success_date ? (
                          <span>
                            {moment(selectedTask.task_success_date).format(
                              'll',
                            )}
                            <br />
                            {moment(selectedTask.task_success_date).format(
                              'HH.mm น.',
                            )}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
