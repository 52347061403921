import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../Loading/SpinnerLoading';
import TaskByStatus from '../Graph/TaskByStatus';
import { TASK_STATUS } from '../../config/TaskStatus';
import { MAIN_PAGE_TAB } from '../../config/WordUtil';
import TableDepartmentNoFilter from '../Table/Container/TableContainerNoFilter';

export default function DepartmentPresentTask({ allTask }) {
  // Variable Define
  const [isLoading, setIsLoading] = useState(false);

  const [taskByStatus, setTaskByStatus] = useState();
  const [pageTabStatus, setPageTabStatus] = useState(MAIN_PAGE_TAB.UNACCEPT);

  useEffect(() => {
    const allTaskByStatus = _.groupBy(allTask, 'task_status');
    setTaskByStatus(allTaskByStatus);
    setIsLoading(true);
    return () => {};
  }, [allTask, pageTabStatus]);

  if (isLoading) {
    return (
      <div>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
          <div className="px-\ ">
            <div className="p-3 m-2 justify-center">
              <div className="flex flex-wrap ">
                <div className=" w-1/2 md:w-1/4 mb-2">
                  <p className="text-sm">ยังไม่มีคนรับ</p>
                  <div className="flex">
                    <p className="text-3xl">
                      {_.size(
                        _.filter(
                          allTask,
                          (task) => task.task_status === TASK_STATUS.INITIAL,
                        ),
                      )}
                    </p>
                    <p className="text-sm self-end mb-1 ml-2">งาน</p>
                  </div>
                </div>
                <div className=" w-1/2 md:w-1/4 mb-2">
                  <p className="text-sm">มีคนรับ </p>
                  <div className="flex">
                    <p className="text-3xl">
                      {_.size(
                        _.filter(
                          allTask,
                          (task) => task.task_status === TASK_STATUS.ACCEPT,
                        ),
                      )}
                    </p>
                    <p className="text-sm self-end mb-1 ml-2">งาน</p>
                  </div>
                </div>
                <div className=" w-1/2 md:w-1/4 mb-2">
                  <p className="text-sm">งานระหว่างดำเนินการ</p>
                  <div className="flex">
                    <p className="text-3xl">
                      {_.size(
                        _.filter(
                          allTask,
                          (task) =>
                            task.task_status === TASK_STATUS.IN_PROGRESS,
                        ),
                      )}
                    </p>
                    <p className="text-sm self-end mb-1 ml-2">งาน</p>
                  </div>
                </div>
                <div className=" w-1/2 md:w-1/4 mb-2">
                  <p className="text-sm">งานที่สำเร็จแล้ว</p>
                  <div className="flex">
                    <p className="text-3xl">
                      {_.size(
                        _.filter(
                          allTask,
                          (task) => task.task_status === TASK_STATUS.SUCCESS,
                        ),
                      )}
                    </p>
                    <p className="text-sm self-end mb-1 ml-2">งาน</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
          <div className="px-\ ">
            <div className="flex p-3 m-2 justify-center">
              <div className="md:w-3/4 ">
                <p className="text-center text-lg">
                  งานที่มีอยู่ในระบบปัจจุบัน
                </p>
                <TaskByStatus allTask={allTask} />
              </div>
            </div>
          </div>
        </div>
        <div className="py-1 md:py-6 flex gap-2">
          <Button
            variant="outline"
            onClick={() => setPageTabStatus(MAIN_PAGE_TAB.UNACCEPT)}
          >
            ยังไม่มีผู้รับ
          </Button>
          <Button
            variant="outline"
            onClick={() => setPageTabStatus(MAIN_PAGE_TAB.IN_PROGRESS)}
          >
            ดำเนินการ
          </Button>
          <Button
            variant="outline"
            onClick={() => setPageTabStatus(MAIN_PAGE_TAB.SUCCESS)}
          >
            เสร็จแล้ว
          </Button>
        </div>

        {pageTabStatus === MAIN_PAGE_TAB.UNACCEPT && (
          <TableDepartmentNoFilter
            taskArray={taskByStatus[TASK_STATUS.INITIAL]}
          />
        )}
        {pageTabStatus === MAIN_PAGE_TAB.IN_PROGRESS && (
          <TableDepartmentNoFilter
            taskArray={_.union(
              taskByStatus[TASK_STATUS.ACCEPT],
              taskByStatus[TASK_STATUS.IN_PROGRESS],
            )}
          />
        )}
        {pageTabStatus === MAIN_PAGE_TAB.SUCCESS && (
          <TableDepartmentNoFilter
            taskArray={taskByStatus[TASK_STATUS.SUCCESS]}
          />
        )}
      </div>
    );
  }
  return <Spinners />;
}
