import React from 'react';

import AllActiveWorkChart from '../../../../../components/Graph/AllActiveWorkChart';
import TaskByStatusGraph from '../../../../../components/Graph/TaskByStatus';

export default function AllActiveWorkContianer({ allDepartment, allTask }) {
  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className="w-full flex flex-wrap p-3 m-2 ">
            <div className="w-full lg:w-2/4">
              <p className="text-center my-2 mb-4">งานที่มีอยู่ในระบบทั้งหมด</p>
              <AllActiveWorkChart
                allDepartment={allDepartment}
                allTask={allTask}
              />
            </div>
            <div className="w-full lg:w-2/4">
              <p className="text-center my-2 mb-4">สถานะงานในระบบขณะนี้</p>
              <TaskByStatusGraph allTask={allTask} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
