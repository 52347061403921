import { LOG_CREATE, LOG_FETCH } from '../../actions/types';

// As same as Assigning Reducer
const initialState = {
  log: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOG_FETCH:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case LOG_CREATE:
      return {
        log: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
