import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

import * as actions from '../../../../redux/actions';
import Spinners from '../../../../components/Loading/SpinnerLoading';
import PersonalTaskStatistic from '../../../../components/Personal/PersonalTaskStatistic';

export default function UserTaskProfile() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  // ดึงState Task ทั้งหมดที่ Assign ให้ คนๆ นี้  แต่เนื่องจากมันเป็น state ที่มี arr,isLoading อยู่
  const taskState = useSelector((state) => state.task);
  const me = useSelector((state) => state.me);
  const allTask = taskState?.arr;

  // UI Rendering
  useEffect(() => {
    console.log('Loading Task Assign to me');
    dispatch(actions.taskAllAssignedToMe(me.employee_id)).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
          สถิติงานที่รับ
        </h2>
        <div className="py-1 md:py-6 flex gap-2">
          <Button variant="outline" onClick={() => history.goBack()}>
            กลับ
          </Button>
        </div>
        <div className="p-4">
          <PersonalTaskStatistic
            departmentId={me.department_id}
            allTask={allTask}
          />
        </div>
      </div>
    );
  }
  return <Spinners />;
}
