// Common Module
export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

// HRMS Module

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_ALL_BY_DEPARTMENT = 'EMPLOYEE_ALL_BY_DEPARTMENT';
export const EMPLOYEE_ALL_BY_DELIVERY = 'EMPLOYEE_ALL_BY_DELIVERY';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';

export const POSITION_ALL = 'POSITION_ALL';
export const POSITION_ALL_BY_DEPARTMENT = 'POSITION_ALL_BY_DEPARTMENT';
export const POSITION_GET = 'POSITION_GET';
export const POSITION_POST = 'POSITION_POST';
export const POSITION_PUT = 'POSITION_PUT';
export const POSITION_DEL = 'POSITION_DEL';
export const POSITION_DEL_BY_DEPARTMENT = 'POSITION_DEL_BY_DEPARTMENT';

export const COMMONWORK_ALL = 'COMMONWORK_ALL';
export const COMMONWORK_GET = 'COMMONWORK_GET';
export const COMMONWORK_BY_DEPARTMENT = 'COMMONWORK_BY_DEPARTMENT';
export const COMMONWORK_POST = 'COMMONWORK_POST';
export const COMMONWORK_DELETE = 'COMMONWORK_DELETE';
export const COMMONWORK_EDIT = 'COMMONWORK_EDIT';

// WMS MODULE

export const TASK_ALL = 'TASK_ALL';
export const TASK_GET = 'TASK_GET';
export const TASK_POST = 'TASK_POST';
export const TASK_PUT = 'TASK_PUT';
export const TASK_DELETE = 'TASK_DELETE';
export const TASK_UNSUCCESS = 'TASK_UNSUCCESS';
export const TASK_UNACCEPT = 'TASK_UNACCEPT';
export const TASK_INPROGRESS = 'TASK_INPROGRESS';
export const TASK_SUCCESS = 'TASK_SUCCESS';
export const TASK_MY_ASSIGN = 'TASK_MY_ASSIGN';
export const TASK_ASSIGN_ME = 'TASK_ASSIGN_ME';
export const TASK_NOTIFY = 'TASK_NOTIFY';

// Comment
export const COMMENT_CREATE = 'COMMENT_CREATE';

// Logs
export const LOG_CREATE = 'LOG_CREATE';
export const LOG_FETCH = 'LOG_FETCH';

// Workstation
export const WORKSTATION_ALL = 'WORKSTATION_ALL';
export const WORKSTATION_GET = 'WORKSTATION_GET';
export const WORKSTATION_POST = 'WORKSTATION_POST';
export const WORKSTATION_PUT = 'WORKSTATION_PUT';
export const WORKSTATION_DELETE = 'WORKSTATION_DELETE';

// Information
export const INFORMATION_GET = 'INFORMATION_GET';
export const INFORMATION_PUT = 'INFORMATION_PUT';
