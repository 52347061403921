/* eslint-disable no-undef */

import React, { useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { firebaseAuth } from '../../../../../contexts/AuthContext';

export default function UserSettingPanel() {
  const me = useSelector((state) => state.me);
  const { handleSignout } = useContext(firebaseAuth);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" my-4 mx-4 overflow-x-auto ">
            <p className="text-xs text-gray-400">ข้อมูลผู้ใช้</p>
            <div className="my-1 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm">โปรไฟล์ผู้ใช้งาน</p>
              </div>
              <div className="w-4/12 text-right">
                <Link to="/wms/dashboard/profile/edit">
                  <Button>แก้ไข</Button>
                </Link>
              </div>
            </div>{' '}
            <hr />
            <div className="my-1 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm">สถิติงานที่รับ</p>
              </div>
              <div className="w-4/12 text-right">
                <Link to="/wms/dashboard/personalstatistic">
                  <Button>ดูสถิติ</Button>
                </Link>
              </div>
            </div>
            <hr />
            <div className="my-1 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm">ออกจากระบบ</p>
              </div>
              <div className="w-4/12 text-right">
                <Button onClick={onSignOut}>Logout</Button>
              </div>
            </div>
            <hr />
          </div>
          <div className=" my-4 mx-4 overflow-x-auto ">
            <p className="text-xs text-gray-400">ข้อมูลภายในแผนก</p>
            {/* <div className="my-1 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm">บุคลากรในแผนก</p>
              </div>
              <div className="w-4/12 text-right">
                <Button>ดูข้อมูล</Button>
              </div>
            </div> <hr /> */}

            <div className="my-1 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm">สถิติงานในแผนก</p>
              </div>
              <div className="w-4/12 text-right">
                <Link to="/wms/dashboard/departmentwork">
                  <Button>ดูสถิติ</Button>
                </Link>
              </div>
            </div>
            <hr />
            <div className="my-1 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm">แจ้งปัญหา หรือ ให้คำแนะนำระบบ</p>
              </div>
              <div className="w-4/12 text-right">
                <Link to="/wms/dashboard/reportandcomment">
                  <Button>แจ้ง</Button>
                </Link>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}
