import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../../redux/actions';
import Spinners from '../../../../components/Loading/SpinnerLoading';
import CardProfile from '../../../../components/common/Cards/CardProfileOnUser';
import NotifyRegisterPanel from './Components/NotifyRegister';
import UserSettingPanel from './Components/UserSettingPanel';

export default function UserProfile() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // ดึงState Task ทั้งหมดที่ Assign ให้ คนๆ นี้  แต่เนื่องจากมันเป็น state ที่มี arr,isLoading อยู่
  const taskState = useSelector((state) => state.task);
  const me = useSelector((state) => state.me);
  const allTask = taskState?.arr;

  // UI Rendering
  useEffect(() => {
    console.log('Loading Task Assign to me');
    dispatch(actions.taskAllAssignedToMe(me.employee_id)).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
          โปรไฟล์
        </h2>

        <div className="p-4">
          <CardProfile
            address={me.employee_address}
            department={me.position?.department?.department_name}
            email={me.employee_email}
            first_name={me.employee_first_name}
            image={me.employee_image}
            last_name={me.employee_last_name}
            phone_number={me.employee_phone_number}
            position={me.position?.position_name}
            role={me.employee_role}
          />
          <NotifyRegisterPanel />
          <UserSettingPanel />
        </div>
      </div>
    );
  }
  return <Spinners />;
}
