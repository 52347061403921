import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import { Button, Td, Tr, Avatar } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { TASK_STATUS } from '../../../config/TaskStatus';

export default function TableListComponent({
  taskData,
  index,
  onAcceptWorkAssignment,
}) {
  return (
    <>
      <Tr>
        <Td>{index + 1}</Td>
        <Td>
          <div className="flex gap-2 align-middle justify-between">
            <Link
              to={`/wms/dashboard/view/${taskData.task_id}`}
              className="font-semibold text-gray-800 hover:text-gray-500 self-center"
            >
              {taskData.task_title}
            </Link>
            <Link
              to={`/wms/dashboard/view/${taskData.task_id}`}
              className="font-semibold text-gray-800 hover:text-gray-500"
            >
              <Button size="sm" variant="outline">
                <i className="far fa-file-alt" />
              </Button>
            </Link>
          </div>
        </Td>
        <Td>{moment(taskData.task_issue_date).format('DD/MM/YYYY HH:mm')}</Td>
        <Td>
          {taskData.task_success_date
            ? moment(taskData.task_success_date).format('DD/MM/YYYY HH:mm')
            : '-'}
        </Td>
        <Td>{taskData.department?.department_name}</Td>
        <Td>
          {taskData.task_status === TASK_STATUS.SUCCESS && (
            <i className="fas fa-check-circle text-green-500 mr-2" />
          )}

          {taskData.task_status === TASK_STATUS.INITIAL && (
            <i className="fas fa-exclamation-circle text-yellow-500 mr-2" />
          )}

          {taskData.task_status}
        </Td>
        <Td>
          {taskData.task_acceptance ? (
            <>
              <div className="flex gap-2">
                <Avatar
                  name={`${taskData.acceptance?.employee_first_name} ${taskData.acceptance?.employee_last_name}`}
                  src={taskData.acceptance?.employee_image}
                  size="xs"
                />

                <h5 className="place-self-center">
                  {taskData.acceptance?.employee_first_name}{' '}
                  {taskData.acceptance?.employee_last_name}
                </h5>
              </div>
            </>
          ) : (
            <>
              <Button
                colorScheme="green"
                leftIcon={<i className="fas fa-calendar-plus" />}
                onClick={() =>
                  onAcceptWorkAssignment(taskData.task_id, taskData)
                }
              >
                รับงาน
              </Button>
            </>
          )}
        </Td>
      </Tr>
    </>
  );
}
