import React from 'react';
import 'moment/locale/th';
import _ from 'lodash';

// components
import CommentList from '../../../Comment/CommentList';

export default function CardViewComment({ allComments, taskId }) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" mt-2 p-2 mx-4">
            {!_.isEmpty(allComments) ? (
              <>
                {' '}
                <div className="text-sm leading-normal mt-0 mb-2">
                  <CommentList allComments={allComments} taskId={taskId} />
                </div>
              </>
            ) : (
              <>
                <p className="text-center"> ยังไม่มีความคิดเห็น</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
