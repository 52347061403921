import React from 'react';
import _ from 'lodash';
import { Avatar, Tooltip } from '@chakra-ui/react';

export default function TaskAssignerAnalysis({ allTask }) {
  const taskIssuerArray = _.map(allTask, 'issuer');
  const UniqueTaskIssuerArray = _.intersectionBy(
    taskIssuerArray,
    'employee_id',
  );

  console.log('Unique Task Acceptance', UniqueTaskIssuerArray);

  return (
    <div>
      <p>พนักงานที่เคยแจ้งงาน</p>

      <div className="flex flex-wrap gap-1">
        {_.map(UniqueTaskIssuerArray, (issuer, index) => (
          <div key={index}>
            <Tooltip
              label={
                issuer.employee_first_name + ' ' + issuer.employee_last_name
              }
            >
              <Avatar
                name={
                  issuer.employee_first_name + ' ' + issuer.employee_last_name
                }
                src={issuer.employee_image}
                size="sm"
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}
