import axios from 'axios';
import { LOG_CREATE, LOG_FETCH } from '../types';

// Create New Log
export const logCreate = (employeeId, logType, message) => async (dispatch) => {
  console.log('Create Log');
  axios
    .post(`${process.env.REACT_APP_API_URL}/logs`, {
      log_employee: employeeId,
      log_type: logType,
      log_message: message,
    })
    .then((res) => {
      console.log('Request to server to create new Log data');
      dispatch({ type: LOG_CREATE, payload: res.data });
    });
};

// Fetch Log From User Selected Date
export const logFetch = (startTime, endTime) => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/logs/${startTime}/${endTime}`)
    .then((res) => {
      console.log('Request to Get Filtered Log data from Database');
      if (res.data) {
        dispatch({ type: LOG_FETCH, payload: res.data });
      } else {
        dispatch({ type: LOG_FETCH, payload: null });
      }
    });
};
