import {
  EMPLOYEE_ALL,
  EMPLOYEE_GET,
  EMPLOYEE_PUT,
  EMPLOYEE_DEL,
  EMPLOYEE_POST,
  EMPLOYEE_ALL_BY_DELIVERY,
  EMPLOYEE_ALL_BY_DEPARTMENT,
} from '../../actions/types';

const initialState = {
  employee: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case EMPLOYEE_ALL_BY_DEPARTMENT:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case EMPLOYEE_ALL_BY_DELIVERY:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case EMPLOYEE_GET:
      return {
        ...action.payload,
        isLoading: true,
      };

    case EMPLOYEE_POST:
      return action.payload;
    case EMPLOYEE_PUT:
      return action.payload;
    case EMPLOYEE_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
