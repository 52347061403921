import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Table, Thead, Tr, Th, Td, Tbody, Button } from '@chakra-ui/react';
import * as UserRoles from 'config/UserRoles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../redux/actions';
import LogTypes from '../../../../config/LogTypes';

export default function UserLists({ employee }) {
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const dispatch = useDispatch();

  const genUserLists = () =>
    _.map(employee, (_employee, index) => (
      <Tr key={_employee._id}>
        <Td>{index + 1}</Td>
        <Td>
          {_employee.employee_first_name} {_employee.employee_last_name}
        </Td>
        <Td>{_employee.employee_phone_number}</Td>
        <Td>
          {_employee.position && _employee.position?.department.department_name}
        </Td>
        <Td>{_employee.position && _employee.position?.position_name}</Td>
        <Td>
          <select
            onChange={(e) => {
              const data = { employee_role: e.target.value };
              const confirm = window.confirm(
                'ยืนยันเปลี่ยนบทบาทบัญชีผู้ใช้จาก' +
                  _employee.employee_role +
                  'เป็น' +
                  e.target.value,
              );
              if (confirm)
                handleUpdate(
                  _employee.employee_id,
                  data,
                  _employee.employee_first_name,
                );
            }}
            defaultValue={_employee.employee_role}
            className="rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            {genUserRolesOptions()}
          </select>
        </Td>

        <Td>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (_employee.employee_role == UserRoles.ROLES.SUPER_USER) {
                alert('ไม่สามารถลบบัญชี SUPERUSER');
              } else {
                const confirm = window.confirm('ยืนยันการลบบัญชีผู้ใช้');
                if (confirm)
                  handleDelelte(
                    _employee.employee_id,
                    _employee.employee_first_name,
                  );
              }
            }}
          >
            ลบ
          </Button>
        </Td>
      </Tr>
    ));

  const genUserRolesOptions = () => (
    <>
      <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
      <option value={UserRoles.ROLES.ADMIN}>{UserRoles.ROLES.ADMIN}</option>
      <option value={UserRoles.ROLES.SUPER_USER}>
        {UserRoles.ROLES.SUPER_USER}
      </option>
    </>
  );

  const handleDelelte = async (id, employeeName) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูลพนักงาน');
    if (confirm) {
      dispatch(
        actions.logCreate(
          me.employee_id,
          LogTypes.USER_EDIT,
          `ทำการลบผู้ใช้งาน ${employeeName} `,
        ),
      );
      dispatch(actions.employeeDelete(id)).then(async () => {
        dispatch(actions.employeeAll());
        alert('ลบสำเร็จ');
      });
    }
  };

  const handleUpdate = async (id, payload, employeeName) => {
    const confirm = window.confirm('ยืนยันการแก้ไขข้อมูลพนักงาน');
    if (confirm) {
      dispatch(
        actions.logCreate(
          me.employee_id,
          LogTypes.USER_EDIT,
          `ทำการเปลี่ยนบทบาทผู้ใช้งานไอดี ${employeeName} เป็น ${payload.employee_role}  `,
        ),
      );
      dispatch(actions.employeeEdit(id, payload)).then(async () => {
        dispatch(actions.employeeAll());
        alert('แก้ไขสำเร็จ');
      });
    }
  };

  return (
    <>
      <div className=" flex-auto overflow-x-auto">
        <div>
          <Table
            size="sm"
            variant="striped"
            className="whitespace-no-wrap overflow-x-auto"
          >
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>ชื่อ-สกุล</Th>
                <Th>เบอร์โทรศัพท์</Th>
                <Th>แผนก</Th>
                <Th>ตำแหน่ง</Th>
                <Th> บทบาท</Th>
                <Th> ดำเนินการ</Th>
              </Tr>
            </Thead>
            <Tbody>{genUserLists()}</Tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
