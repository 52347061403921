import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

export default function EditPosition() {
  const { register, handleSubmit, errors } = useForm();
  const { id, id2 } = useParams();
  const history = useHistory();
  const department = useSelector((state) => state.department);
  const position = useSelector((state) => state.position);
  const [positionSelect, setPositionSelect] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.departmentAll());
    dispatch(actions.positionAll());
    return () => {};
  }, []);

  const genDepartmentOption = () =>
    _.map(department, (_department) => {
      if (_department.department_id == id) {
        return (
          <option
            key={_department.department_id}
            selected
            value={_department.department_id}
          >
            {_department.department_name}
          </option>
        );
      } else {
        return (
          <option
            key={_department.department_id}
            value={_department.department_id}
          >
            {_department.department_name}
          </option>
        );
      }
    });
  const onSubmit = (data, e) => {
    dispatch(actions.positionEdit(positionSelect[1], data)).then(() => {
      console.log('แก้ไขตำแหน่งสำเร็จ');
      dispatch(actions.positionAll());
      history.push('/hrms/department/details/' + id);
    });
  };

  const findPosition = () => {
    const positionFilter = _.find(position, (_position) => {
      if (_position.position_id == id2) return _position;
    });

    if (positionFilter !== undefined) {
      positionSelect[0] = positionFilter.position_name;
      positionSelect[1] = positionFilter.position_id;
    }
  };

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">แก้ไขตำแหน่ง</h2>
      <div className="flex justify-between py-6">
        <Link to={'/hrms/department/details/' + id}>
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      {findPosition()}
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {' '}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อตำแหน่ง
                </label>
                <input
                  name="position_name"
                  type="text"
                  required
                  ref={register}
                  defaultValue={positionSelect[0]}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อตำแหน่ง"
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  แผนก
                </label>
                <select
                  name="department_id"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                >
                  {genDepartmentOption()}
                </select>
              </div>

              <div className="relative w-full mb-3">
                <div className="text-center mt-6">
                  <Button isFullWidth colorScheme="yellow" type="submit">
                    แก้ไข
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
