import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import CardEachEmployeeStatistic from '../../../../components/common/Cards/Employee/CardEachEmployeeStatistic';

export default function PercapitaDashboard() {
  // Variable Define
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const task = useSelector((state) => state.task);
  const department = useSelector((state) => state.department);
  const employee = useSelector((state) => state.employee);
  const [taskByPerson, setTaskByPerson] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [filteredEmployee, setFilteredEmployee] = useState(employee.arr);

  // Get Selected Task Data
  useEffect(() => {
    dispatch(actions.employeeAll()).then(() => {
      dispatch(actions.departmentAll());
      dispatch(actions.taskAll()).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (task.arr) {
      const tempTaskByPerson = _.groupBy(task.arr, 'task_acceptance');
      setTaskByPerson(tempTaskByPerson);
      console.log('Task By Person', tempTaskByPerson);
    }
    return () => {};
  }, [task]);

  useEffect(() => {
    console.log('Filter Select Department');
    if (selectedDepartment !== '') {
      const tempEmployeeInDepartment = _.filter(
        employee.arr,
        (eachEmployee) => eachEmployee.department_id === selectedDepartment,
      );
      setFilteredEmployee(tempEmployeeInDepartment);
    } else {
      setFilteredEmployee(employee.arr);
    }
    return () => {};
  }, [selectedDepartment, task]);

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1">
          สถิติการทำงานของแต่ละคน
        </h2>

        <div className="py-4 md:py-6">
          <Button
            variant="outline"
            onClick={() => {
              history.push('/');
            }}
          >
            กลับ
          </Button>
        </div>
        <div className="p-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
            <div className="px-\ ">
              <div className=" mt-2 ml-4 md:w-1/3 p-3">
                <Select
                  placeholder="เลือกแผนกที่ต้องการ"
                  onChange={(event) =>
                    setSelectedDepartment(event.target.value)
                  }
                >
                  <option value="">ทุกแผนก</option>
                  {_.map(department, (depart, index) => (
                    <option key={index} value={depart.department_id}>
                      {depart.department_name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {_.map(filteredEmployee, (eachEmployee, index) => (
            <CardEachEmployeeStatistic
              employee={eachEmployee}
              allTask={taskByPerson[eachEmployee.employee_id]}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }
  return <Spinners />;
}
