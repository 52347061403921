import { COMMENT_CREATE } from "../../actions/types"

// As same as Assigning Reducer
const initialState = {
  comment: null,
  isLoading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case COMMENT_CREATE:
      return action.payload

    default:
      return state
  }
}
