import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Table, Thead, Tr, Th, Td, Tbody, Badge } from '@chakra-ui/react';

import LogTypes from '../../../../config/LogTypes';
import moment from 'moment';

export default function UserLists({ logs }) {
  const selectColor = (logType) => {
    switch (logType) {
      case LogTypes.OTHERS:
        return 'black';
      case LogTypes.SYSTEM_EDIT:
        return 'yellow';
      case LogTypes.USER_CREATE:
        return 'cyan';
      case LogTypes.USER_EDIT:
        return 'red';
      case LogTypes.WORK_ACCEPT:
        return 'green';
      case LogTypes.WORK_COLLAB:
        return 'teal';
      case LogTypes.WORK_CREATE:
        return 'orange';
      default:
        return 'gray';
    }
  };

  const generateLogList = () =>
    _.map(_.orderBy(logs, 'createdAt'), (log, index) => (
      <Tr key={log._id}>
        <Td>{index + 1}</Td>
        <Td>{moment(log.createdAt).format('D MMM HH:mm ')}</Td>
        <Td>
          <Badge colorScheme={selectColor(log.log_type)}>{log.log_type} </Badge>
        </Td>
        <Td>
          {log.employee?.employee_first_name} {log.employee?.employee_last_name}
        </Td>
        <Td>{log.log_message}</Td>
      </Tr>
    ));

  return (
    <>
      <div className=" flex-auto overflow-x-auto">
        <div>
          <Table
            size="sm"
            variant="striped"
            className="whitespace-no-wrap overflow-x-auto"
          >
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>วัน/เวลา</Th>
                <Th>ประเภท</Th>
                <Th>ผู้ใช้</Th>
                <Th>เหตุการณ์</Th>
              </Tr>
            </Thead>
            <Tbody>{generateLogList()}</Tbody>
          </Table>
        </div>
      </div>
    </>
  );
}
