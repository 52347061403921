import axios from "axios";
import {
  DEPARTMENT_ALL,
  DEPARTMENT_GET,
  DEPARTMENT_PUT,
  DEPARTMENT_DEL,
  DEPARTMENT_POST,
} from "../types";

export const departmentAll = () => {
  return async(dispatch) => {
   await axios.get(process.env.REACT_APP_API_URL + "/department").then((res) => {
      console.log("Request Server to Get All Departments");
      dispatch({ type: DEPARTMENT_ALL, payload: res.data });
    });
  };
};

export const departmentGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/department/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Departments");
        if (res.data) {
          dispatch({ type: DEPARTMENT_GET, payload: res.data });
        } else {
          dispatch({ type: DEPARTMENT_GET, payload: null });
        }
      });
  };
};

export const departmentPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/department/", payload)
      .then((res) => {
        console.log("Request Server to Post an Departments");
        dispatch({ type: DEPARTMENT_POST, payload: res.data });
      });
  };
};

export const departmentDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/department/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Departments");
        dispatch({ type: DEPARTMENT_DEL, payload: res.data });
      });
  };
};

export const departmentEdit = (id,payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/department/" + id, payload)
      .then((res) => {
        console.log("Request Server to Edit an Department");
        if (res.data) {
          dispatch({ type: DEPARTMENT_PUT, payload: res.data  });
          dispatch(departmentAll());
        }
      });
  };
};