/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';

import '@fortawesome/fontawesome-free/css/all.min.css';
// eslint-disable-next-line import/no-unresolved
import './assets/styles/tailwind.css';
import './assets/styles/print.css';

// Notification
import { messaging } from './config/firebase/firebase-client';

// PrivateRoute Contexts
import PrivateRoute from './contexts/PrivateRoute';
// AuthProvider Contexts

// Home or Portal Menu
import Home from './views/Index';

// layouts
import Auth from './layouts/Auth';
import HRMS from './layouts/HRMS';
import WMS from './layouts/wms/index';

import AuthProvider from './contexts/AuthContext';
import configureStore from './redux/configureStore';
import * as serviceWorker from './serviceWorker';

const store = configureStore();

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <AuthProvider>
        <ChakraProvider>
          <div className="font-sans">
            <Switch>
              {/* add routes with layouts */}
              <Route path="/auth" component={Auth} />

              {/* PrivateRoute */}
              <PrivateRoute>
                <Route exact path="/" component={Home} />
                <Route path="/hrms" component={HRMS} />
                <Route path="/wms" component={WMS} />
              </PrivateRoute>
              {/* add redirect for NotFound page */}
              <Redirect from="*" to="/auth" />
            </Switch>
          </div>
        </ChakraProvider>
      </AuthProvider>
    </Provider>
  </Router>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);

// Register Service Worker on non ios device only
if (
  navigator.platform !== 'iPhone' &&
  navigator.platform !== 'iPad' &&
  navigator.platform !== 'iPod' &&
  messaging
) {
  console.log('Non IOS Detected !');
  Notification.requestPermission((status) => {
    console.log('Notification Permission Status ', status);
  });

  const displayNotification = (payload) => {
    if (Notification.permission === 'granted') {
      navigator.serviceWorker.getRegistration().then((reg) => {
        const notifyOption = {
          body: payload.notification?.body,
          image: payload.notification?.image
            ? payload.notification?.image
            : undefined,
          action: 'click-action',
        };
        const notifyTitle = payload.notification?.title;
        console.log('Notify Payload ', payload);
        reg.showNotification(notifyTitle, notifyOption);
      });
    }
  };

  messaging.onMessage((payload) => {
    displayNotification(payload);
  });

  if ('Notification' in window && navigator.serviceWorker) {
    // Display the UI to let the user toggle notifications
  }

  serviceWorker.register();
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(() => {
      console.log('Service Worker Registered');
    });
  }
} else {
  console.log('IOS Detected');
}
