/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import _ from 'lodash';
import InfinteScroll from 'react-infinite-scroll-component';

import Spinners from '../../../components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import CardAcceptableTaskData from '../../../components/common/Cards/Task/CardAcceptableTaskData';
import PageTab from '../../../config/MainPageTabStatus';
import { ROLES } from '../../../config/UserRoles';
import { MAIN_PAGE_TAB } from '../../../config/WordUtil';

export default function OverallWorkingDashboard() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { PageTabStatus } = PageTab;
  const [size, setSize] = useState(10);
  const [pageTabStatus, setPageTabStatus] = useState(PageTabStatus.UNACCEPT);
  const [isShowAllDepartment, setIsShowAllDepartment] = useState(true);

  /* ดึง State Task ทั้งหมดที่ Assign ให้ Department นี้
    แต่เนื่องจากมันเป็น state ที่มี arr,isLoading อยู่ */
  const taskState = useSelector((state) => state.task);
  const me = useSelector((state) => state.me);
  const allTask = taskState?.arr;

  /* Get Task that assign to this Department
   ถ้าเราจะมาเช็คดูก่อนว่า Role ของ User เป็นระดับ Admin หรือ SuperUser มั้ย
   ถ้ามีให้เขา Access All แต่ถ้าไม่มี เราก็จะให้ดูแค่ department ของเขา */
  // const adminPermission = !!(me?.employee_role === ROLES.SUPER_USER);

  // Change Status
  const changeToInProgress = () => {
    setIsLoading(false);
    localStorage.setItem('FRONTPAGE_SESSION', MAIN_PAGE_TAB.IN_PROGRESS);
    if (isShowAllDepartment) {
      console.log('Loging in with Admin or Superuser');
      dispatch(actions.taskAllInProgress(1, size)).then(() => {
        setIsLoading(true);
      });
    } else {
      dispatch(
        actions.taskDepartmentInProgress(
          me?.position?.department?.department_id,
        ),
      ).then(() => {
        setIsLoading(true);
      });
    }
    setPageTabStatus(PageTabStatus.IN_PROGRESS);
  };

  const changeToSuccess = () => {
    setIsLoading(false);
    localStorage.setItem('FRONTPAGE_SESSION', MAIN_PAGE_TAB.SUCCESS);
    if (isShowAllDepartment) {
      console.log('Loging in with Admin or Superuser');
      dispatch(actions.taskAllSuccess(1, size)).then(() => {
        setIsLoading(true);
      });
    } else {
      dispatch(
        actions.taskDepartmentSuccess(me?.position?.department?.department_id),
      ).then(() => {
        setIsLoading(true);
      });
    }
    setPageTabStatus(PageTabStatus.SUCCESS);
  };

  const changeToUnAccept = () => {
    setIsLoading(false);
    localStorage.setItem('FRONTPAGE_SESSION', MAIN_PAGE_TAB.UNACCEPT);
    if (isShowAllDepartment) {
      console.log('Loging in with Admin or Superuser');
      dispatch(actions.taskAllUnAccept(1, size)).then(() => {
        setIsLoading(true);
      });
    } else {
      dispatch(
        actions.taskDepartmentUnAccept(me?.position?.department?.department_id),
      ).then(() => {
        setIsLoading(true);
      });
    }
    setPageTabStatus(PageTabStatus.UNACCEPT);
  };

  const acceptWorkAssignment = (taskId, taskData) => {
    // eslint-disable-next-line no-undef
    const confirm = window.confirm('ยืนยันการรับงาน');
    if (confirm) {
      setIsLoading(false);
      dispatch(actions.taskAccept(taskId, me.employee_id)).then(() => {
        dispatch(
          actions.taskNotifyDirecly(
            taskData.task_issuer,
            'มีผู้รับงานของคุณแล้ว',
            `${me.employee_first_name} ได้รับงาน ${taskData.task_title} ของคุณแล้ว`,
            `https://seaseeker.eappsoft.net/wms/dashboard/view/${taskData.task_id}`,
          ),
        );

        dispatch(
          actions.lineTaskNotifyDirecly(
            taskData.task_issuer,
            `${me.employee_first_name} ได้รับงาน ${taskData.task_title} ของคุณแล้ว`,
          ),
        );
        // It will directly go into task data page because Link tag is over that
        dispatch(actions.taskGet(taskId));
      });
    }
  };

  /** เพื่อให้เข้ากับ Feeling ผู็ใช้ เราจะดูก่อนว่าเมื่อกี้ผู้ใช้ได้อยู่ในสเตทไหน
   * แล้วเก็บไว้ใน localstorage
   * แล้วพอตอนก่อนที่จะเอามาแสดงเราก็เช็คใน Storage ว่ามันเป็นยังไง
   */
  useEffect(() => {
    const frontpageCurrentSession = localStorage.getItem('FRONTPAGE_SESSION');
    if (frontpageCurrentSession) {
      switch (frontpageCurrentSession) {
        case MAIN_PAGE_TAB.UNACCEPT:
          changeToUnAccept();
          break; // Default Case
        case MAIN_PAGE_TAB.IN_PROGRESS:
          changeToInProgress();
          break;
        case MAIN_PAGE_TAB.SUCCESS:
          changeToSuccess();
          break;
        default:
          changeToUnAccept();
          break;
      }
    } else {
      changeToUnAccept();
    }
    return () => {};
  }, [size, isShowAllDepartment]);

  // UI Rendering

  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
          งานทั้งหมด
        </h2>
        <div className="flex flex-wrap justify-between">
          <div className="py-1 md:py-6 flex gap-2">
            <Button
              onClick={() => changeToUnAccept()}
              colorScheme={
                pageTabStatus === PageTabStatus.UNACCEPT ? 'blue' : 'gray'
              }
              variant="outline"
            >
              ยังไม่รับ
            </Button>
            <Button
              onClick={() => changeToInProgress()}
              colorScheme={
                pageTabStatus === PageTabStatus.IN_PROGRESS ? 'blue' : 'gray'
              }
              variant="outline"
            >
              ดำเนินการ
            </Button>
            <Button
              onClick={() => changeToSuccess()}
              colorScheme={
                pageTabStatus === PageTabStatus.SUCCESS ? 'blue' : 'gray'
              }
              variant="outline"
            >
              เสร็จแล้ว
            </Button>{' '}
          </div>
          <div>
            <div className="flex gap-2">
              <Switch
                isChecked={!isShowAllDepartment}
                onChange={() => setIsShowAllDepartment(!isShowAllDepartment)}
              />
              <FormLabel>เฉพาะแผนกของฉัน</FormLabel>
            </div>
          </div>
        </div>
        <div className="p-2 md:p-4">
          {_.isEmpty(allTask) ? (
            <>
              <p className="text-center">ยังไม่มีงานในแผนกนี้</p>
            </>
          ) : (
            <>
              <InfinteScroll
                dataLength={allTask.length}
                loader={<Spinners />}
                next={() => {
                  setSize(size + 5);
                }}
                hasMore={size < taskState?.allSize}
              >
                {_.map(
                  _.orderBy(allTask, 'task_issue_date', 'desc'),
                  (task, index) => (
                    <CardAcceptableTaskData
                      selectedTask={task}
                      key={index}
                      onAcceptWorkAssignment={acceptWorkAssignment}
                      me={me}
                    />
                  ),
                )}
              </InfinteScroll>
            </>
          )}
        </div>
      </div>
    );
  }
  return <Spinners />;
}
