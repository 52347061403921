/*eslint-disable*/
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { firebaseAuth } from 'contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
// components

import { Button } from '@chakra-ui/react';
import AppLogo from '../../../assets/img/seaseekerlogo-removebg.png';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const { handleSignout } = useContext(firebaseAuth);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-gray-800 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
            >
              <div className="flex gap-2">
                <img src={information?.system_logo} className=" h-10" />
                <span className="self-center">
                  {information?.system_name || 'e-WMS'}
                </span>
              </div>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none' +
              (navbarOpen ? ' block' : ' hidden')
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link to="/auth/login">
                  <Button
                    size="xs"
                    leftIcon={<i className="fas fa-door-open"></i>}
                    variant="outline"
                    onClick={() => {
                      onSignOut();
                    }}
                  >
                    Sign Out
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
