/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import { Avatar, Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import 'moment/locale/th';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { TASK_STATUS } from '../../../../config/TaskStatus';
// components

export default function CardAcceptableTaskData({
  selectedTask,
  onAcceptWorkAssignment,
  me,
}) {
  return (
    <>
      <Link to={`/wms/dashboard/view/${selectedTask.task_id}`}>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
          <div className="px-\ ">
            <div className=" mt-2 ml-4">
              <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                {/** Photo and Name of Assinger */}
                <div className="flex gap-2">
                  <Avatar
                    name={`${selectedTask.issuer?.employee_first_name} ${selectedTask.issuer?.employee_last_name}`}
                    src={selectedTask.issuer?.employee_image}
                    size="xs"
                  />

                  <h5 className="place-self-center">
                    {selectedTask.issuer?.employee_first_name}{' '}
                    {selectedTask.issuer?.employee_last_name}
                    {/** Department นี้จะเป็น Department ของงานที่เราแจ้งไป */}
                    <span className="text-gray-300">
                      <ChevronRightIcon />
                      {selectedTask.department?.department_name}
                    </span>
                  </h5>
                </div>
              </div>
              <div className="my-4  text-gray-700 ">
                <h6 className="text-gray-400 text-sm my-2">
                  {moment(selectedTask.task_issue_date).format('lll')}
                  {'  '} <i className="fas fa-map-marker-alt mr-1" />
                  {selectedTask.task_place}
                </h6>
                <h6 className="text-gray-400 text-sm my-2">
                  {selectedTask.workstation && (
                    <i className="fas fa-building mx-1" />
                  )}
                  {selectedTask.workstation?.workstation_name}
                </h6>
                <h4 className="text-lg font-semibold">
                  {selectedTask.task_title}
                </h4>

                {/* <p className="text-base text-gray-500">
                  {selectedTask.task_description}
                </p> */}

                {selectedTask.taskImages ? (
                  <>
                    <div className="flex flex-wrap gap-2 ">
                      {_.map(selectedTask.taskImages, (taskImage, index) => (
                        <img
                          key={index}
                          src={taskImage.image_string}
                          className="max-h-48"
                          alt="task"
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <br />
              <div className="flex gap-2 mb-4 flex-wrap">
                <div className="mr-4 md:w-1/5">
                  <p className="text-sm text-gray-600">สถานะ</p>
                  <h5>
                    {selectedTask.task_status === TASK_STATUS.INITIAL ? (
                      <div>
                        <i className="fas fa-exclamation-circle" />{' '}
                        {selectedTask.task_status}{' '}
                      </div>
                    ) : (
                      selectedTask.task_status
                    )}
                  </h5>
                </div>
                {selectedTask.task_acceptance ? (
                  <>
                    <div className="md:w-1/4">
                      <p className="text-sm text-gray-600">
                        <i className="fas fa-user" /> ผู้รับงาน
                      </p>
                      <div className="flex gap-2">
                        <Avatar
                          name={`${selectedTask.acceptance?.employee_first_name}  ${selectedTask.acceptance?.employee_last_name}`}
                          src={selectedTask.acceptance?.employee_image}
                          size="xs"
                        />

                        <h5 className="place-self-center">
                          {selectedTask.acceptance?.employee_first_name}{' '}
                          {selectedTask.acceptance?.employee_last_name}
                        </h5>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="md:w-1/5">
                      {/* <p className="text-sm text-gray-600">
                      <i className="fas fa-user"></i> งานนี้ยังว่างอยู่
                    </p>
                    <br /> */}
                      { /* prettier-ignore */}
                      <Button
                        colorScheme="green"
                        leftIcon={<i className="fas fa-calendar-plus" />}
                        disabled={selectedTask?.task_department !== me?.department_id}
                        onClick={() => onAcceptWorkAssignment(
                          selectedTask.task_id,
                          selectedTask,
                        )}
                      >
                        รับงาน
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
