import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export default function TaskAverageTime({ taskArray }) {
  const successTask = _.filter(
    taskArray,
    (task) => task.task_success_date !== null,
  );
  const successTaskAmount = _.size(successTask);

  const sumTime = _.reduce(
    successTask,
    (sum, task) => {
      return (
        sum +
        moment(task.task_success_date).diff(
          moment(task.task_issue_date),
          'minutes',
          true,
        )
      );
    },
    0,
  );

  const averageTime = sumTime / successTaskAmount;

  return (
    <div>
      <p>ใช้เวลาเฉลี่ย</p>
      <h4 className="text-xl">
        {!_.isNaN(averageTime) ? parseFloat(averageTime).toFixed(2) : '-'}{' '}
      </h4>
      <p className="text-sm">นาที / งาน</p>
    </div>
  );
}
