import axios from 'axios';
import { TASK_NOTIFY } from '../types';
import { TASK_STATUS as TaskStatus } from '../../../config/TaskStatus';

export const lineNotifyRegister = (userId, accessCode) => async (dispatch) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/notify/line/register`, {
      userId,
      accessCode,
    })
    .then(() => {
      console.log('Request Line for a authorization token');
      dispatch({ type: TASK_NOTIFY, payload: null });
    });
};

// Create Notify all around department
export const lineTaskNotifyDepartment = (departmentId, message) => async (
  dispatch,
) => {
  console.log(
    `In Function Notify to all everyone in department ${departmentId}`,
  );
  await axios
    .post(`${process.env.REACT_APP_API_URL}/notify/line/department`, {
      departmentId,
      message,
    })
    .then(() => {
      console.log('Request to Notify task Task');
      dispatch({ type: TASK_NOTIFY, payload: null });
    })
    .catch((error) => {
      console.log('cannot notify but create success', error);
      dispatch({ type: TASK_NOTIFY, payload: null });
    });
};

// Create Notify to one person directly
export const lineTaskNotifyDirecly = (userId, message) => async (dispatch) => {
  console.log('Notify Individully ');
  await axios
    .post(`${process.env.REACT_APP_API_URL}/notify/line/direct`, {
      userId,
      message,
    })
    .then(() => {
      console.log('Request to Notify task ');
      dispatch({ type: TASK_NOTIFY, payload: null });
    })
    .catch((error) => {
      console.log('cannot notify but create success', error);
      dispatch({ type: TASK_NOTIFY, payload: null });
    });
};
