import React from 'react';
import _ from 'lodash';
import { Avatar, Tooltip } from '@chakra-ui/react';

export default function TaskTakerAnalysis({ allTask }) {
  const taskWithAcceptance = _.filter(
    allTask,
    (task) => task.acceptance !== null,
  );
  const taskAcceptanceArray = _.map(taskWithAcceptance, 'acceptance');
  const uniqueTaskAcceptanceArray = _.intersectionBy(
    taskAcceptanceArray,
    'employee_id',
  );

  console.log('Unique Task Acceptance', uniqueTaskAcceptanceArray);

  return (
    <div>
      <p>พนักงานที่เคยทำ</p>

      <div className="flex flex-wrap gap-1">
        {_.map(uniqueTaskAcceptanceArray, (acceptor, index) => (
          <div key={index}>
            <Tooltip
              label={
                acceptor.employee_first_name + ' ' + acceptor.employee_last_name
              }
            >
              <Avatar
                name={
                  acceptor.employee_first_name +
                  ' ' +
                  acceptor.employee_last_name
                }
                src={acceptor.employee_image}
                size="sm"
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
}
