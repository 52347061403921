/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

export default function ReportAndComment() {
  const history = useHistory();

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
        แก้ไขงาน
      </h2>

      <div className="py-6 hidden md:block">
        <Button variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <form action="https://formspree.io/f/mqkwkopg" method="post">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  แจ้งปัญหา หรือ ให้คำแนะนำ
                </label>
                <textarea
                  name="comment_data"
                  type="textfield"
                  className="px-3 py-3 placeholder-gray-400  text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  style={{ height: '200px' }}
                  placeholder="คำติชม ปัญหาที่พบ สิ่งที่ต้องการ หรือ คำแนะนำต่อการพัฒนาระบบ"
                />
              </div>

              <div className="relative w-full mb-3">
                <div className="text-center mt-6">
                  <Button isFullWidth colorScheme="blue" type="submit">
                    ส่งความคิดเห็น
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
