import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

// components
import Navbar from '../components/common/Navbars/AuthNavbar';
import FooterSmall from '../components/common/Footers/FooterSmall';
import Loading from '../components/Loading/SpinnerLoading';

// views
import Login from 'views/auth/Signin.js';
import Register from 'views/auth/Register.js';
import ForgottenPassword from 'views/auth/ForgottenPassword';

import * as actions from '../redux/actions';

export default function Auth() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.informationGet()).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  if (!isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-cover 	"
            style={{
              backgroundImage: 'url(' + require('assets/img/wms-bg.jpg') + ')',
              opacity: '80%',
            }}
          ></div>

          <Switch>
            {window.localStorage.token ? (
              <>
                <Redirect from="/" to="/" />
              </>
            ) : (
              <>
                <Route path="/auth/login" exact component={Login} />
                {/* <Route path="/auth/register" exact component={Register} /> */}

                <Route path="/auth/register" exact component={Register} />

                <Route
                  path="/auth/forgotten-password"
                  exact
                  component={ForgottenPassword}
                />
                {/* <Redirect from="/auth" to="/auth/login" /> */}
              </>
            )}
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
