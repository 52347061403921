/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Select } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import LogTypes from '../../../../config/LogTypes';

export default function EditTask() {
  const { register, handleSubmit } = useForm();
  const me = useSelector((state) => state.me);
  const department = useSelector((state) => state.department);
  const workstation = useSelector((state) => state.workstation);
  const taskData = useSelector((state) => state.task);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkstation, setSelectedWorkstation] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();

  useEffect(() => {
    dispatch(actions.departmentAll()).then(() => {
      dispatch(actions.commonWorkAll()).then(() => {
        dispatch(actions.workstationAll());
        dispatch(actions.taskGet(param.id)).then(() => {
          setIsLoading(true);
        });
      });
    });
    return () => {};
  }, []);

  const workstationList = () =>
    _.map(workstation?.arr, (eachStation, index) => (
      <option key={index} value={eachStation.workstation_id}>
        {eachStation.workstation_name}
      </option>
    ));

  const onSubmit = async (data, e) => {
    const confirm = window.confirm('ยืนยันการแก้ไขงาน');
    if (confirm) {
      console.log('Data that summit ', data);
      const tempTaskPayload = data;
      // ใส่ค่าที่มันยังขาดอยู่ลงไป
      tempTaskPayload.task_issue_date = taskData.task_issue_date;
      tempTaskPayload.task_issuer = taskData.task_issuer;
      tempTaskPayload.task_status = taskData.task_status;
      tempTaskPayload.task_id = taskData.task_id;

      const submittedTaskPayload = tempTaskPayload;
      console.log('Task Payload', submittedTaskPayload);
      e.preventDefault();

      dispatch(
        actions.logCreate(
          me.employee_id,
          LogTypes.WORK_COLLAB,
          `แก้ไขงาน ${tempTaskPayload.task_title}  `,
        ),
      );

      dispatch(
        actions.taskNotifyDepartment(
          tempTaskPayload.task_department,
          `มีงานใหม่เข้ามาที่ ${tempTaskPayload.task_place}`,
          tempTaskPayload.task_title,
          undefined,
          'https://seaseeker.eappsoft.net/wms/dashboard/view/' +
            tempTaskPayload.task_id,
        ),
      );
      dispatch(
        actions.lineTaskNotifyDepartment(
          tempTaskPayload.task_department,
          `มีงานเข้ามาใหม่ที่ ${tempTaskPayload.task_place} 😊 ${tempTaskPayload.task_title} ดูงานนี้เลยที่ https:://seaseeker.eappsoft.net/wms/dashboard/view/${tempTaskPayload.task_id}   `,
        ),
      );

      dispatch(actions.taskEdit(taskData.task_id, submittedTaskPayload)).then(
        () => {},
      );
      history.goBack();
    }
  };

  if (isLoading === true) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
          แก้ไขงาน
        </h2>

        <div className="py-6 hidden md:block">
          <Button variant="outline" onClick={() => history.goBack()}>
            กลับ
          </Button>
        </div>
        <div className="p-4">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    แผนกที่ต้องการมอบหมายงาน
                  </label>
                  <div>
                    <Select
                      placeholder="เลือกแผนก"
                      bg="white"
                      name="task_department"
                      defaultValue={taskData.task_department}
                      ref={register}
                    >
                      {_.map(department, (eachDepartment, index) => (
                        <option
                          value={eachDepartment.department_id}
                          key={index}
                        >
                          {eachDepartment.department_name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    ชื่องาน
                  </label>
                  <textarea
                    name="task_title"
                    type="textfield"
                    required
                    ref={register}
                    className="px-3 py-3 placeholder-gray-400  text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    style={{ height: '200px' }}
                    placeholder="รายละเอียด"
                    defaultValue={taskData.task_title}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    สถานที่แจ้ง
                    {/* TODO: Change to General */}
                  </label>
                  <div>
                    <Select
                      placeholder="เลือกสถานที่ต้องการแจ้ง"
                      bg="white"
                      name="task_workstation"
                      ref={register}
                      defaultValue={taskData.task_workstation}
                      onChange={(event) => {
                        setSelectedWorkstation(event.target.value);
                      }}
                    >
                      {workstationList()}
                    </Select>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    สถานที่
                  </label>
                  <input
                    name="task_place"
                    type="text"
                    ref={register}
                    defaultValue={taskData.task_place}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="สถานที่ของงาน"
                  />
                </div>
                <div className="relative w-full mb-3">
                  <div className="text-center mt-6">
                    <Button isFullWidth colorScheme="blue" type="submit">
                      แก้ไขงานที่มอบหมายไว้
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Spinners />;
}
