/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import hash from 'object-hash';

import * as actions from '../../../../redux/actions';
import { CommentType } from '../../../../config/CommentType';
import ImageUpload from '../../../ImageUpload/ImageUpload';
import { uploadImageFile } from '../../../../util/image.functions';

// components

export default function CardCreateComment({
  taskId,
  onUpdateComment,
  taskData,
}) {
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [image, setImage] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState('');

  const handleCreateComment = async (data, event) => {
    const userId = me.employee_id;
    const commentInitailData = data;

    commentInitailData.comment_taskid = taskId;
    commentInitailData.comment_timestamp = new Date();
    commentInitailData.comment_userid = userId;
    commentInitailData.comment_type = CommentType.NORMAL;

    let imageDataString = [];
    console.log('Image', image);
    for (let i = 0; i < _.size(image); i++) {
      if (image && image[i]) {
        setIsModalOpen(true);
        setModalText('กำลังอัพโหลดรูปภาพ');
        console.log(` Image Available for image ${i + 1}`);
        const filename = hash({ image: image[i], date: new Date() });

        // อัพโหลลดรูปภาพ โดยเอารูปภาพที่ผู้ใช้ใส่มา Push ลงไปใน Array ImageDataString
        await uploadImageFile(filename, image[i].data_url).then(async (url) => {
          console.log(`URL of Image ${i + 1} : ${url}`);

          // เพื่อกันความผิดพลาด กลัวว่า Empty Array จะ Push แล้วมีปัญหา
          // ดังนั้น กรณีเป็นรูปแรก เราจะเปลี่ยนค่า Array เป็นค่า Array ของ String ที่มีค่าๆ นึง
          if (_.isEmpty(imageDataString)) {
            imageDataString = [url];
          } else {
            imageDataString.push(url);
          }

          // เมื่อถึงรูปตัวสุดท้าย มันจะเท่ากับขนาดของ image array - 1 ก็จะให้มันเก็บค่า
          // ของ Array ของ urlString ไว้ใน Attribute ของ iamgeDataString
          // จากนั้นก็ติดไปที่ payload ของ Action ไปด้วย
          // แล้วค่อยไปแยกอีกทีว่าอันไหน ไปที่ไหน ลงตารางไหนใน Database
          if (i === _.size(image) - 1) {
            commentInitailData.imageData = imageDataString;
            console.log('Upload Full of All images');
            setModalText('อัพโหลดรูปภาพเสร็จสิ้น กำลังสร้างคอมเมนต์');
            console.log(commentInitailData);

            event.preventDefault();

            dispatch(actions.createNewComment(commentInitailData))
              .then(() => {
                setImage([]);
                onUpdateComment();
                setModalText('สร้างคอมเมนต์สำเร็จ กำลังสร้างการแจ้งเตือน');

                dispatch(
                  actions.taskNotifyDirecly(
                    taskData.task_issuer,
                    `มีข้อความใหม่ จาก ${me.employee_first_name}`,
                    commentInitailData.comment_message,
                    `https://seaseeker.eappsoft.net/wms/dashboard/view/${taskId}`,
                  ),
                );
                dispatch(
                  actions.lineTaskNotifyDirecly(
                    taskData.task_issuer,
                    `มีข้อความใหม่ จาก ${me.employee_first_name} 📞 ${commentInitailData.comment_message} `,
                  ),
                );
              })
              .then(() => {
                setModalText('กำลังสร้างคอมเมนต์');
                setTimeout(() => {
                  setIsModalOpen(false);
                }, 1000);
              });
          }
        });
      }
    }
    if (_.size(image) === 0) {
      // กรณีผู็ใช้ไม่ได้ใส่รูปมา ก็ไม่ต้องทำอะไร จัดการไปปกติ
      console.log('No Image');

      event.preventDefault();
      setIsModalOpen(true);
      setModalText('กำลังสร้างคอมเมนต์');
      dispatch(actions.createNewComment(commentInitailData))
        .then(() => {
          setImage([]);
          setModalText('สร้างคอมเมนต์สำเร็จ กำลังสร้างการแจ้งเตือน');

          dispatch(
            actions.taskNotifyDirecly(
              taskData.task_issuer,
              `มีข้อความใหม่ จาก ${me.employee_first_name}`,
              commentInitailData.comment_message,
              `https://seaseeker.eappsoft.net/wms/dashboard/view/${taskId}`,
            ),
          ).then(() => {
            onUpdateComment();
          });
          dispatch(
            actions.lineTaskNotifyDirecly(
              taskData.task_issuer,
              `มีข้อความใหม่ จาก ${me.employee_first_name} 📞 ${commentInitailData.comment_message} `,
            ),
          );
        })
        .then(() => {
          setModalText('กำลังสร้างคอมเมนต์');
          setTimeout(() => {
            setIsModalOpen(false);
          }, 1000);
        });
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <Modal isOpen={isModalOpen} closeHandle={() => setIsModalOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody className="my-4 font-sans">
              <div className="flex justify-center">
                <Spinner />
              </div>
              <h5 className="text-center text-lg font-semibold">{modalText}</h5>
            </ModalBody>
          </ModalContent>
        </Modal>
        <div className="px-\ ">
          <div className=" mt-2 p-2 mx-4">
            <h3 className="text-lg font-semibold leading-normal mb-2 text-gray-800 ">
              ความคิดเห็น
            </h3>

            <div className="text-sm leading-normal mt-0 mb-2">
              <form onSubmit={handleSubmit(handleCreateComment)}>
                <div className=" w-full p-3  mb-2 ">
                  <p
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    แสดงความเห็น
                  </p>
                  <input
                    name="comment_message"
                    type="text"
                    required
                    className="px-3 py-3  placeholder-gray-400  text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    style={{ height: '60px' }}
                    ref={register}
                    placeholder="แสดงความเห็น"
                  />

                  <div className="flex justify-end mt-2 gap-2">
                    <div>
                      <ImageUpload
                        images={image}
                        setImages={setImage}
                        preview_size="100px"
                        maxNumber={5}
                        disablePreview
                      />
                    </div>
                    <Button colorScheme="blue" type="submit">
                      แสดงความเห็น
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
