/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import 'moment/locale/th';
import { Avatar, Button } from '@chakra-ui/react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../../../redux/actions';
import { TASK_STATUS } from '../../../../config/TaskStatus';

// components
export default function CardEachEmployeeStatistic({ employee, allTask }) {
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  const userDepatmentData = _.find(
    department,
    (dept) => dept.department_id === employee.department_id,
  );

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" mt-2 ml-4">
            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
              {/** Photo and Name of Assinger */}
              <div className="flex gap-2">
                <Avatar
                  name={`${employee.employee_first_name} ${employee.employee_last_name}`}
                  src={employee.employee_image}
                  size="sm"
                />

                <h5 className="place-self-center">
                  {employee.employee_first_name} {employee.employee_last_name}{' '}
                  <span className="text-gray-400">
                    {userDepatmentData ? userDepatmentData.department_name : ''}
                  </span>
                </h5>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-2/4 md:w-1/4">
                <h5 className="text-sm">จำนวนงานที่รับ</h5>
                <div className="flex">
                  <h3 className="text-3xl"> {_.size(allTask)} </h3>
                  <p className="text-sm self-end ml-2"> งาน </p>
                </div>
              </div>
              <div className="w-2/4 md:w-1/4">
                <h5 className="text-sm">ปิดงานสำเร็จ</h5>
                <div className="flex">
                  <h3 className="text-3xl">
                    {' '}
                    {allTask
                      ? _.size(
                          _.filter(
                            allTask,
                            (task) => task.task_status === TASK_STATUS.SUCCESS,
                          ),
                        )
                      : 0}{' '}
                  </h3>
                  <p className="text-sm self-end ml-2"> งาน </p>
                </div>
              </div>
              <div className="w-2/4 md:w-1/4">
                <h5 className="text-sm">อยู่ระหว่างการทำงาน</h5>
                <div className="flex">
                  <h3 className="text-3xl">
                    {' '}
                    {allTask
                      ? _.size(
                          _.filter(
                            allTask,
                            (task) =>
                              task.task_status === TASK_STATUS.IN_PROGRESS,
                          ),
                        )
                      : 0}{' '}
                  </h3>
                  <p className="text-sm self-end ml-2"> งาน </p>
                </div>
              </div>
              <div className="w-2/4 md:w-1/4">
                <h5 className="text-sm">ยังไม่เริ่มทำงาน</h5>
                <div className="flex">
                  <h3 className="text-3xl">
                    {' '}
                    {allTask
                      ? _.size(
                          _.filter(
                            allTask,
                            (task) => task.task_status === TASK_STATUS.ACCEPT,
                          ),
                        )
                      : 0}{' '}
                  </h3>
                  <p className="text-sm self-end ml-2"> งาน </p>
                </div>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
