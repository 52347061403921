import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

import * as actions from '../../../redux/actions/index';
import LogTypes from '../../../config/LogTypes';

export default function EditDepartment() {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);
  const { id } = useParams();
  const [departmentSelect, setDepartmentSelect] = useState([]);

  useEffect(() => {
    dispatch(actions.departmentAll());
    return () => {};
  }, []);

  const onSubmit = (data, e) => {
    dispatch(
      actions.logCreate(
        me.employee_id,
        LogTypes.SYSTEM_EDIT,
        `แก้ไขข้อมูลในแผนก ${data.department_name}`,
      ),
    );
    dispatch(actions.departmentEdit(id, data)).then(() => {
      console.log('แก้ไขแผนกสำเร็จ');
      history.push('/hrms/department');
    });
  };

  const findDepartment = () => {
    const departmentFilter = _.find(department, (_department) => {
      if (_department.department_id == id) return _department;
    });

    if (departmentFilter !== undefined) {
      departmentSelect[0] = departmentFilter.department_name;
      departmentSelect[1] = departmentFilter.department_detail;
    }
  };

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">แก้ไขแผนก</h2>

      <div className="py-6">
        <Link to="/hrms/department">
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      {findDepartment()}
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {' '}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อแผนก
                </label>
                <input
                  name="department_name"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อแผนก"
                  defaultValue={departmentSelect[0]}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รายละเอียด
                </label>
                <textarea
                  name="department_detail"
                  type="textfield"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="รายละเอียด"
                  defaultValue={departmentSelect[1]}
                />
                <div className="text-center mt-6">
                  <Button isFullWidth colorScheme="yellow" type="submit">
                    แก้ไข
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
