/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';

// components
import AcceptTaskView from './Tab/Assigned';
import AssignTaskView from './Tab/Assigining';
import PageTab from '../../../../config/MainPageTabStatus';

export default function MobileViewAllTask() {
  const { PersonalPageTabStatus } = PageTab;
  // Set Default to View Assigining and the next one will be Assigned
  const [pageTabStatus, setPageTabStatus] = useState(
    PersonalPageTabStatus.MY_ASSIGN,
  );

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">งานทั้งหมด</h2>

      <div className="py-1 md:py-6 flex gap-2 overflow-x-auto">
        <Button
          colorScheme={
            pageTabStatus === PersonalPageTabStatus.MY_ASSIGN ? 'blue' : 'gray'
          }
          onClick={() => setPageTabStatus(PersonalPageTabStatus.MY_ASSIGN)}
          variant="outline"
        >
          งานที่แจ้งไว้
        </Button>
        <Button
          colorScheme={
            pageTabStatus === PersonalPageTabStatus.MY_ACCEPT ? 'blue' : 'gray'
          }
          onClick={() => setPageTabStatus(PersonalPageTabStatus.MY_ACCEPT)}
          variant="outline"
        >
          งานที่รับ
        </Button>
      </div>
      <div className="py-4 w-full">
        {pageTabStatus === PersonalPageTabStatus.MY_ASSIGN && (
          <AssignTaskView />
        )}
        {pageTabStatus === PersonalPageTabStatus.MY_ACCEPT && (
          <AcceptTaskView />
        )}
      </div>
    </div>
  );
}
