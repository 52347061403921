/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import {
  Button,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import hash from 'object-hash';

// components
import { uploadImageFile } from '../../../../util/image.functions';
import { TASK_STATUS as TaskStatus } from '../../../../config/TaskStatus';
import { CommentType } from '../../../../config/CommentType';
import * as actions from '../../../../redux/actions';
import LogTypes from '../../../../config/LogTypes';
import ImageUpload from '../../../ImageUpload/ImageUpload';

export default function CardTaskStart({ selectedTask, onUpdateStatus }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [commentOnClose, setCommentOnClose] = useState('');
  const [image, setImage] = useState([]);

  const handleStartWorking = () => {
    const modifiedTask = selectedTask;
    modifiedTask.task_startprogress_date = new Date();
    modifiedTask.task_status = TaskStatus.IN_PROGRESS;

    dispatch(
      actions.logCreate(
        me.employee_id,
        LogTypes.WORK_COLLAB,
        `เริ่มงาน ${modifiedTask.task_title} ที่รับไว้ `,
      ),
    );

    dispatch(actions.taskEdit(selectedTask.task_id, modifiedTask)).then(() => {
      onUpdateStatus();
    });
  };

  const handleTaskClosing = async () => {
    const modifiedTask = selectedTask;
    modifiedTask.task_success_date = new Date();
    modifiedTask.task_status = TaskStatus.SUCCESS;
    let imageDataString = [];
    console.log('Image ', image);
    for (let i = 0; i < _.size(image); i++) {
      if (image && image[i]) {
        console.log(` Image Available for image ${i + 1}`);
        const filename = hash({ image: image[i], date: new Date() });

        // อัพโหลลดรูปภาพ โดยเอารูปภาพที่ผู้ใช้ใส่มา Push ลงไปใน Array ImageDataString
        await uploadImageFile(filename, image[i].data_url).then(async (url) => {
          console.log(`URL of Image ${i + 1} : ${url}`);

          // เพื่อกันความผิดพลาด กลัวว่า Empty Array จะ Push แล้วมีปัญหา
          // ดังนั้น กรณีเป็นรูปแรก เราจะเปลี่ยนค่า Array เป็นค่า Array ของ String ที่มีค่าๆ นึง
          if (_.isEmpty(imageDataString)) {
            imageDataString = [url];
          } else {
            imageDataString.push(url);
          }

          // เมื่อถึงรูปตัวสุดท้าย มันจะเท่ากับขนาดของ image array - 1 ก็จะให้มันเก็บค่า
          // ของ Array ของ urlString ไว้ใน Attribute ของ iamgeDataString
          // จากนั้นก็ติดไปที่ payload ของ Action ไปด้วย
          // แล้วค่อยไปแยกอีกทีว่าอันไหน ไปที่ไหน ลงตารางไหนใน Database
          if (i === _.size(image) - 1) {
            const commentPayload = {
              comment_taskid: selectedTask.task_id,
              comment_timestamp: new Date(),
              comment_userid: selectedTask.task_acceptance,
              comment_type: CommentType.CLOSE,
              comment_message: commentOnClose,
              imageData: imageDataString,
            };

            console.log('Upload Full of All images');
            console.log(commentPayload);

            dispatch(actions.createNewComment(commentPayload)).then(() => {
              setImage([]);
            });

            dispatch(actions.taskEdit(selectedTask.task_id, modifiedTask)).then(
              () => {
                dispatch(
                  actions.taskNotifyDepartment(
                    selectedTask.task_department,
                    `งานของแผนกคุณสำเร็จแล้ว ${selectedTask.task_title} `,
                    `${me.employee_first_name} ได้จัดการงานนี้สำเร็จแล้ว`,
                    undefined,
                    `https://seaseeker.eappsoft.net/wms/dashboard/view/${selectedTask.task_id}`,
                  ),
                );
                dispatch(
                  actions.lineTaskNotifyDepartment(
                    selectedTask.task_department,
                    `งาน ${selectedTask.task_title} ของแผนกคุณ ได้ทำงานเสร็จเรียบร้อยแล้ว โดย ${me.employee_first_name} `,
                  ),
                );
                onUpdateStatus();
              },
            );
          }
        });
      }
    }
    if (_.size(image) === 0) {
      // กรณีผู็ใช้ไม่ได้ใส่รูปมา ก็ไม่ต้องทำอะไร จัดการไปปกติ
      console.log('No Image');

      const commentPayload = {
        comment_taskid: selectedTask.task_id,
        comment_timestamp: new Date(),
        comment_userid: selectedTask.task_acceptance,
        comment_type: CommentType.CLOSE,
        comment_message: commentOnClose,
        imageData: imageDataString,
      };
      dispatch(actions.createNewComment(commentPayload)).then(() => {
        setImage([]);
      });
      dispatch(actions.taskEdit(selectedTask.task_id, modifiedTask)).then(
        () => {
          dispatch(
            actions.taskNotifyDepartment(
              selectedTask.task_department,
              `งานของแผนกคุณสำเร็จแล้ว ${selectedTask.task_title} `,
              `${me.employee_first_name} ได้จัดการงานนี้สำเร็จแล้ว`,
              undefined,
              `https://seaseeker.eappsoft.net/wms/dashboard/view/${selectedTask.task_id}`,
            ),
          );
          dispatch(
            actions.lineTaskNotifyDepartment(
              selectedTask.task_department,
              `งาน ${selectedTask.task_title} ของแผนกคุณ ได้ทำงานเสร็จเรียบร้อยแล้ว โดย ${me.employee_first_name} `,
            ),
          );
          onUpdateStatus();
        },
      );
    }
    dispatch(
      actions.logCreate(
        me.employee_id,
        LogTypes.WORK_COLLAB,
        `ปิดงาน ${modifiedTask.task_title} ที่รับไว้ `,
      ),
    );
  };

  if (selectedTask.task_status === TaskStatus.ACCEPT) {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
          <div className="px-\ ">
            <div className=" mt-2 ml-4">
              <div className="my-4">
                <div className="text-center ">
                  <p className="mb-2">
                    คุณเป็นคนรับงานนี้แล้ว คุณจะเริ่มทำงานนี้เลยหรือไม่
                  </p>
                  <Button
                    colorScheme="green"
                    onClick={() => handleStartWorking()}
                  >
                    เริ่มทำงาน
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (selectedTask.task_status === TaskStatus.IN_PROGRESS) {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
          <div className="px-\ ">
            <div className=" mt-2 ml-4">
              <div className="my-4">
                <div className="text-center ">
                  <p className="mb-2">งานเสร็จแล้วใช่มั้ย</p>
                  <Button colorScheme="green" onClick={onOpen}>
                    สรุปและปิดงาน
                  </Button>
                  <Modal
                    isOpen={isOpen}
                    isCentered
                    onClose={onClose}
                    motionPreset="slideInBottom"
                  >
                    <ModalOverlay />
                    <ModalContent className="font-sans">
                      <ModalHeader> ยินดีด้วย คุณทำงานสำเร็จ</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <p>ต้องการบันทึกอะไรไหม</p>
                        <Textarea
                          className="my-2 "
                          placeholder="ต้องการบันทึกอะไรไหม
                          เพื่อเพื่อนร่วมกันจะได้ทำงานง่ายขึ้น เมื่อมีงานแบบนี้"
                          // prettier-ignore
                          onChange={(event) => setCommentOnClose(event.target.value)}
                        />
                        <p>ต้องการเพิ่มรูปภาพไหม</p>
                        <div className="my-2">
                          <ImageUpload
                            images={image}
                            setImages={setImage}
                            preview_size="100px"
                            maxNumber={5}
                          />
                        </div>
                      </ModalBody>
                      <Button
                        colorScheme="blue"
                        onClick={() => handleTaskClosing()}
                      >
                        บันทึกงาน
                      </Button>
                    </ModalContent>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <></>;
}
