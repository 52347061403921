import {
  COMMONWORK_ALL,
  COMMONWORK_BY_DEPARTMENT,
  COMMONWORK_DELETE,
  COMMONWORK_GET,
  COMMONWORK_POST,
} from '../../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case COMMONWORK_POST:
      return action.payload;
    case COMMONWORK_ALL:
      return action.payload;
    case COMMONWORK_BY_DEPARTMENT:
      return action.payload;
    case COMMONWORK_DELETE:
      return action.payload;
    case COMMONWORK_GET:
      return action.payload;
    default:
      return state;
  }
}
