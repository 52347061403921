import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// insided Layout
import WorkDashbaord from './WorkDashboard';
import History from './History';

export default function WMSRoute() {
  return (
    <Switch>
      {/* add routes with layouts  inside WMS */}
      <Route path="/wms/dashboard" component={WorkDashbaord} />
      <Route path="/wms/history" component={History} />
      {/* add redirect for Default Page */}
      <Redirect from="/wms" to="/wms/dashboard" />
    </Switch>
  );
}
