/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import 'moment/locale/th';
import _ from 'lodash';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Avatar, Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import LogTypes from '../../../../config/LogTypes';
import * as actions from '../../../../redux/actions';

// components
export default function CardTaskData({ selectedTask }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  const acceptWorkAssignment = (taskId, taskData) => {
    // eslint-disable-next-line no-undef
    const confirm = window.confirm('ยืนยันการรับงาน');
    if (confirm) {
      dispatch(actions.taskAccept(taskId, me.employee_id)).then(() => {
        dispatch(
          actions.logCreate(
            me.employee_id,
            LogTypes.WORK_ACCEPT,
            `ได้รับงาน ${taskData.task_title}`,
          ),
        );
        dispatch(
          actions.taskNotifyDirecly(
            taskData.task_issuer,
            'มีผู้รับงานของคุณแล้ว',
            `${me.employee_first_name} ได้รับงาน ${taskData.task_title} ของคุณแล้ว`,
          ),
        ).then(() => {});
        dispatch(
          actions.lineTaskNotifyDirecly(
            taskData.task_issuer,
            `${me.employee_first_name} ได้รับงาน ${taskData.task_title} ของคุณแล้ว`,
          ),
        );
        dispatch(actions.taskGet(taskId));
      });
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" mt-2 ml-4">
            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
              {/** Photo and Name of Assinger */}
              <div className="flex gap-2">
                <Avatar
                  name={`${selectedTask.issuer?.employee_first_name} ${selectedTask.issuer?.employee_last_name}`}
                  src={selectedTask.issuer?.employee_image}
                  size="xs"
                />

                <h5 className="place-self-center">
                  {selectedTask.issuer?.employee_first_name}{' '}
                  {selectedTask.issuer?.employee_last_name}
                  {/** Department นี้จะเป็น Department ของงานที่เราแจ้งไป */}
                  <span className="text-gray-300">
                    <ChevronRightIcon />
                    {selectedTask.department?.department_name}
                  </span>
                </h5>
              </div>
            </div>
            <h6 className="text-gray-400 text-sm my-2">
              {moment(selectedTask.task_issue_date).format('lll')}
              {'  '} <i className="fas fa-map-marker-alt mr-1" />
              {selectedTask.task_place} <i className="fas fa-building mx-1" />
              {selectedTask.workstation?.workstation_name}
            </h6>

            <div className="my-4  text-gray-700 ">
              <h4 className="text-lg font-semibold">
                {selectedTask.task_title}{' '}
              </h4>

              {selectedTask.taskImages ? (
                <>
                  <div className="flex flex-wrap gap-2 ">
                    {_.map(selectedTask.taskImages, (taskImage, index) => (
                      <img
                        src={taskImage.image_string}
                        className="max-h-48"
                        alt={selectedTask.task_title}
                        key={index}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <br />
            <div className="flex gap-2 mb-4 flex-wrap">
              <div className="md:w-1/5 mr-4">
                <p className="text-sm text-gray-600">
                  <i className="fas fa-info-circle" /> สถานะ
                </p>
                <h5> {selectedTask.task_status} </h5>
              </div>
              <div className="md:w-1/5">
                <p className="text-sm text-gray-600">
                  <i className="fas fa-user" /> ผู้รับงาน
                </p>
                <h5>
                  {selectedTask.task_acceptance ? (
                    <>
                      <div className="flex gap-2">
                        <Avatar
                          name={`${selectedTask.acceptance?.employee_first_name} ${selectedTask.acceptance?.employee_last_name}`}
                          src={selectedTask.acceptance?.employee_image}
                          size="xs"
                        />

                        <h5 className="place-self-center">
                          {selectedTask.acceptance?.employee_first_name}{' '}
                          {selectedTask.acceptance?.employee_last_name}
                          {/** Department นี้จะเป็น Department ของงานที่เราแจ้งไป */}
                        </h5>
                      </div>
                    </>
                  ) : (
                    <div>
                      {me &&
                      me.department_id === selectedTask.task_department ? (
                        <Button
                          colorScheme="green"
                          leftIcon={<i className="fas fa-calendar-plus" />}
                          // prettier-ignore
                          onClick={() => acceptWorkAssignment(
                            selectedTask.task_id,
                            selectedTask,
                          )}
                        >
                          รับงาน
                        </Button>
                      ) : (
                        'ยังไม่มีคนรับงาน'
                      )}
                    </div>
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
