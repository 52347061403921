/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Select } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LineNotifyRegister() {
  const me = useSelector((state) => state.me);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const query = useQuery();

  useEffect(() => {
    const accessCode = query.get('code');
    const userId = query.get('state');
    dispatch(actions.lineNotifyRegister(userId, accessCode)).then(() => {
      history.push('/wms/dashboard/profile');
    });

    return () => {};
  }, []);

  if (isLoading === true) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1 hidden md:block">
          รับการแจ้งเตือนผ่านไลน์
        </h2>

        <div className="py-6 hidden md:block">
          <Button variant="outline" onClick={() => history.goBack()}>
            กลับ
          </Button>
        </div>
        <div className="p-4"></div>
      </div>
    );
  }

  return <Spinners />;
}
