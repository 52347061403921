import React from 'react';
import PropTypes from 'prop-types';
// components
import { ROLES } from '../../../config/UserRoles';

export default function CardProfile({
  first_name,
  last_name,
  department,
  position,
  email,
  phone_number,
  address,
  postcode,
  role,
  image,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-\">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative">
                {image ? (
                  <>
                    {' '}
                    <img
                      alt="..."
                      src={image}
                      className="shadow-xl rounded-full h-auto align-middle border-none -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </>
                ) : (
                  <>
                    {' '}
                    <img
                      alt="..."
                      src={require('assets/img/favicon-eappsoft.png')}
                      className="shadow-xl rounded-full h-auto align-middle border-none -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="text-center  mt-20">
            <h3 className="text-xl font-semibold leading-normal  text-gray-800 mb-2">
              {first_name + ' ' + last_name}
            </h3>
            {role !== ROLES.USER && (
              <div className="text-base leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200  last:mr-0 mr-1">
                  {role}
                </span>
              </div>
            )}
            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
              {/* <i className="fas fa-briefcase  mr-2 text-lg text-gray-500"></i>{" "} */}
              <h4>แผนก : {department}</h4>
            </div>

            <div className="mb-2 text-gray-700 ">
              {/* <i className="fas fa-building mr-2 text-lg text-gray-500"></i> */}
              <h4>ตำแหน่ง : {position}</h4>
            </div>
          </div>
          <div className="w-full px-4 text-center mt-2 md:mt-4 py-2">
            <div className="flex flex-wrap justify-center  pt-1 lg:pt-4 ">
              <div className="lg:mr-4 md:pt-3 px-3 text-center">
                <i className="fa fa-phone hidden md:block"></i>
                <span className="text-sm font-bold block uppercase tracking-wide text-gray-700">
                  เบอร์โทรศัพท์ : {phone_number}
                </span>
                <span className="text-sm text-gray-500"> </span>
              </div>
              <div className="mr-4 p-3 text-center">
                <i className="fa fa-mail-bulk fa-1x hidden md:block"></i>
                <span className="text-sm font-bold block uppercase tracking-wide text-gray-700">
                  อีเมล : {email}
                </span>
                <span className="text-sm text-gray-500"> </span>
              </div>
            </div>
          </div>
          <div className=" hidden lg:block mt-10 py-10 border-t border-gray-300 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <p className="mb-4 text-sm leading-relaxed text-gray-800">
                  ที่อยู่ : {address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CardProfile.defaultProps = {
  first_name: 'ชื่อ',
  last_name: 'สกุล',
  department: 'แผนก',
  position: 'ตำแหน่ง',
  email: 'อีเมล',
  phone_number: 'เบอร์โทรศัพท์',
  address: 'ที่อยู่',
  postcode: 'รหัสไปรษณีย์',
  image: null,
  role: ROLES.USER,
};

CardProfile.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  department: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  address: PropTypes.string,
  postcode: PropTypes.string,
  image: PropTypes.string,
  role: PropTypes.string,
};
