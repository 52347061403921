import axios from 'axios';
import {
  WORKSTATION_ALL,
  WORKSTATION_DELETE,
  WORKSTATION_GET,
  WORKSTATION_POST,
  WORKSTATION_PUT,
} from '../types';

export const workstationAll = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/workstation`)
    .then((res) => {
      console.log('Request Server to Get All Workstations');
      dispatch({ type: WORKSTATION_ALL, payload: res.data });
    });
};

export const workstationGet = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/workstation/${id}`)
    .then((res) => {
      console.log('Request Server to Get a workstation');
      if (res.data) {
        dispatch({ type: WORKSTATION_GET, payload: res.data });
      } else {
        dispatch({ type: WORKSTATION_GET, payload: null });
      }
    });
};

export const workstationPost = (payload) => async (dispatch) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/workstation/`, payload)
    .then((res) => {
      console.log('Request Server to Post a workstation');
      dispatch({ type: WORKSTATION_POST, payload: res.data });
    });
};

export const workstationDelete = (id) => async (dispatch) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/workstation/${id}`)
    .then((res) => {
      console.log('Request Server to Delete workstation by ID');
      if (res.data) {
        dispatch({ type: WORKSTATION_DELETE, payload: null });
      }
    });
};

export const workstationEdit = (id, payload) => async (dispatch) => {
  await axios
    .put(`${process.env.REACT_APP_API_URL}/workstation/${id}`, payload)
    .then((res) => {
      console.log('Request Server to Edit an workstation');
      if (res.data) {
        dispatch({ type: WORKSTATION_PUT, payload: res.data });
      }
    });
};
