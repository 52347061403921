import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import CardTaskData from '../../../../components/common/Cards/Task/CardTaskData';
import CardTaskProgress from '../../../../components/common/Cards/Task/CardTaskProgress';
import CardTaskCreateComment from '../../../../components/common/Cards/Task/CardTaskCreateComment';
import CardViewComment from '../../../../components/common/Cards/Task/CardTaskViewComment';
import CardTaskStart from '../../../../components/common/Cards/Task/CardTaskStart';

export default function ViewTask() {
  // Variable Define
  const param = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const taskId = param.id;
  const [isLoading, setIsLoading] = useState(false);
  const task = useSelector((state) => state.task);
  const me = useSelector((state) => state.me);
  // Get Selected Task Data
  useEffect(() => {
    dispatch(actions.taskGet(taskId)).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, [taskId]);

  const handleNewUpdate = () => {
    setIsLoading(false);
    dispatch(actions.taskGet(taskId)).then(() => {
      setIsLoading(true);
    });
  };

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className=" text-gray-800 text-xl font-semibold py-1">
          รายละเอียดงาน
        </h2>

        <div className="py-2 md:py-6">
          <Button
            variant="outline"
            onClick={() => {
              history.goBack();
            }}
          >
            กลับ
          </Button>
        </div>
        <div className="p-4">
          <CardTaskData selectedTask={task} />
          <br />
          {task.task_acceptance === me.employee_id ? (
            <>
              <CardTaskStart
                selectedTask={task}
                onUpdateStatus={handleNewUpdate}
              />
            </>
          ) : (
            <></>
          )}
          <CardTaskProgress selectedTask={task} />

          <CardViewComment allComments={task.comments} taskId={task.task_id} />

          <CardTaskCreateComment
            taskId={task.task_id}
            taskData={task}
            onUpdateComment={handleNewUpdate}
          />
        </div>
      </div>
    );
  }
  return <Spinners />;
}
