const PageTabStatus = {
  UNACCEPT: 'unaccept',
  IN_PROGRESS: 'inprogress',
  SUCCESS: 'success',
  ALL: 'all',
};

const PersonalPageTabStatus = {
  MY_ACCEPT: 'My Accept Work',
  MY_ASSIGN: 'MY Assign Work',
  DEPT_ACCEPT: 'Department Accepted Work',
  DEPT_ASSIGN: 'Department Assigned Work',
};

export default { PageTabStatus, PersonalPageTabStatus };
