/*eslint-disable*/
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { firebaseAuth } from 'contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { ROLES } from '../../../../config/UserRoles';

import _ from 'lodash';

import AppLogo from '../../../../assets/img/seaseekerlogo-removebg.png';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const { handleSignout } = useContext(firebaseAuth);
  const history = useHistory();

  // Get All Allowed Departments (that we have fetch in layout)
  const departments = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  // Sidebar Dashboard จะต่างจากตัวอื่น คือ เมื่อคลิกที่โลโก้ข้างบน มันก็จะกลับมาที่ /wms/dashboard ไม่กลับไปที่หน้าแรก
  // เพราะส่วนใหญ่คนจะใช้หน้านี้ และ มี Field เพิ่มให้สำหรับคนที่เป็น Admin หรือ Superuser

  return (
    <>
      <nav className=" print-disable  lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-no-wrap lg:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between  lg:w-64 z-10 py-1 px-6 sticky top-0">
        <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto ">
          {/* Toggler */}
          {/* {me?.employee_role === ROLES.ADMIN ||
            (me?.employee_role === ROLES.SUPER_USER && (
              <button
                className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
              >
                <i className="fas fa-bars"></i>
              </button>
            ))} */}

          {/* Brand */}
          <Link
            className="lg:block text-left lg:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-2 lg:p-4 px-0"
            to="/wms/dashboard"
          >
            <div className="flex gap-2">
              <img src={information?.system_logo} className="h-10" />
              <span className="self-center">{information?.system_name}</span>
            </div>
          </Link>
          {/* User */}
          <ul className="lg:hidden items-center flex flex-wrap list-none">
            {/* <li className="inline-block relative">
              <NotificationDropdown />
            </li> */}
            <li className="inline-block relative">{/* <UserDropdown /> */}</li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="lg:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="lg:block text-left lg:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/wms/dashboard"
                  >
                    <div className="flex gap-2">
                      <img src={information?.system_logo} className="h-10" />
                      <span className="self-center">
                        {information?.system_name}
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Heading */}

            <h6 className="lg:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              รายการงาน
            </h6>
            {/* Navigation */}

            <ul className="lg:flex-col lg:min-w-full flex flex-col list-none">
              <li className="items-center">
                {/** แสดงรายการงานทั้งหมด แต่เราสามารถกำหนดข้างในได้แล้วว่า ของใครมีสิทธิที่จะเห็นแค่ไหน */}
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/wms/dashboard') !== -1 &&
                    _.last(_.split(window.location.href, '/wms/dashboard')) ===
                      ''
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/wms/dashboard"
                >
                  <i
                    className={
                      'fas fa-calendar mr-2 text-sm ' +
                      (window.location.href.indexOf('/wms/dashboard') !== -1 &&
                      _.last(
                        _.split(window.location.href, '/wms/dashboard'),
                      ) === ''
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  รายการงาน
                </Link>
              </li>

              {/** แยก Tab ตาม Department ตอนนี้เอาออกไปก่อน  เพราะเราสามารถกรองรายการงานให้สามารถโชว์เฉพาะของ Department เราได้แล้ว */}

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/wms/dashboard/assign') !==
                    -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/wms/dashboard/assign"
                >
                  <i
                    className={
                      'fas fa-pen-alt mr-2 text-sm ' +
                      (window.location.href.indexOf('/wms/dashboard/assign') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  แจ้งงาน
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/wms/dashboard/myassign') !==
                    -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/wms/dashboard/myassign"
                >
                  <i
                    className={
                      'fas fa-file mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/wms/dashboard/myassign',
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  สถานะงานที่แจ้ง
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/wms/dashboard/departmentwork',
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/wms/dashboard/departmentwork/"
                >
                  <i
                    className={
                      'fas fa-list mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/wms/dashboard/departmentwork',
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  งานในแผนก
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/wms/dashboard/profile') !==
                    -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/wms/dashboard/profile"
                >
                  <i
                    className={
                      'fas fa-user mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/wms/dashboard/profile',
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }
                  ></i>{' '}
                  โปรไฟล์
                </Link>
              </li>
            </ul>

            <hr className="my-4 lg:min-w-full" />
            <ul className="lg:flex-col lg:min-w-full flex flex-col list-none lg:mb-4">
              {me?.employee_role === ROLES.ADMIN ||
              me?.employee_role === ROLES.SUPER_USER ? (
                <>
                  <Link to="/">
                    <li className="items-center">
                      <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer">
                        <i className="fas fa-cog text-gray-500 mr-2 text-sm"></i>{' '}
                        จัดการระบบ
                      </div>
                    </li>
                  </Link>
                </>
              ) : (
                <></>
              )}
              <li className="items-center">
                <div
                  className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                  onClick={() => {
                    onSignOut();
                  }}
                >
                  <i className="fas fa-door-open text-gray-500 mr-2 text-sm"></i>{' '}
                  Sign Out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
