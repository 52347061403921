import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@chakra-ui/react';
import SpinnerLoading from '../../../components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import WorkstationList from './components/WorkStationList';

export default function ManagementWorkstation() {
  const workStations = useSelector((state) => state.workstation);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('On HRMS Dashboard ');
    dispatch(actions.workstationAll()).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        สถานีงานทั้งหมด
      </h2>
      <div className="flex justify-between py-6">
        <Link to="/hrms/workstation/create">
          <Button colorScheme="blue" variant="solid">
            เพิ่ม
          </Button>
        </Link>
      </div>
      <div className="py-4 w-full">
        {_.size(workStations.arr) ? (
          <div>
            <WorkstationList workStations={workStations.arr} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
