/* eslint-disable no-undef */
// สร้างพื้นที่สำหรับเก็บ Element ของตาราง

import React from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  messaging,
  vapidKey,
} from '../../../../../config/firebase/firebase-client';

import * as actions from '../../../../../redux/actions';
import LineNotifyComponent from '../../../../../components/LineNotify/NotifyComponent';
import LineNotifyConfig from '../../../../../config/LineNotify';

export default function NotifyRegister() {
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const dispatch = useDispatch();

  const requestNotification = () => {
    let browser;
    if (navigator.userAgent.indexOf('Edg') !== -1) {
      browser = 'Microsoft Edge';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      browser = 'Google Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      browser = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      browser = 'Mozilla Firefox';
    } else {
      browser = 'Internet Browser';
    }

    let platform;
    if (navigator.platform === 'Win32') platform = 'Windows';
    else if (navigator.platform.indexOf('Linux arm') !== -1) {
      platform = 'Android';
    } else platform = navigator.platform;

    if (
      navigator.platform !== 'iPhone' &&
      navigator.platform !== 'iPad' &&
      navigator.platform !== 'iPod' &&
      messaging
    ) {
      messaging.getToken({ vapidKey }).then((token) => {
        dispatch(
          actions.employeeEdit(me.employee_id, {
            employee_allow_notify: true,
            employee_notify_token: token,
            employee_notify_agent: `${browser} ${platform}`,
          }),
        ).then(() => {
          dispatch(actions.meGet());
        });
      });
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
        <div className="px-\ ">
          <div className=" my-4 mx-4 overflow-x-auto  ">
            <p className="text-xs text-gray-400">การแจ้งเตือน</p>

            {navigator.platform !== 'iPhone' &&
            navigator.platform !== 'iPad' &&
            navigator.platform !== 'iPod' ? (
              <div className="my-2 w-full flex">
                {me.employee_notify_token === null ? (
                  <div>
                    <div className="w-8/12 self-center">
                      <p className="text-sm ">การแจ้งเตือนผ่านบราวเซอร์</p>
                      <small className="text-gray-400 text-xs">
                        สำหรับคอมพิวเตอร์ และ สมาร์ทโฟนระบบ Android เท่านั้น
                      </small>
                    </div>
                    <div className="w-4/12 text-right">
                      <Button onClick={() => requestNotification()}>
                        รับการแจ้งเตือน
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-2 justify-between w-full ">
                    <div className="w-6/12 self-center">
                      <p className="text-sm ">การแจ้งเตือนผ่านบราวเซอร์</p>
                      <small className="text-gray-400 text-xs">
                        สำหรับคอมพิวเตอร์ และ สมาร์ทโฟนระบบ Android เท่านั้น
                      </small>
                    </div>

                    <div className="w-4/12 text-right">
                      <div className="flex justify-end gap-2">
                        <span className="self-center text-sm">
                          {me.employee_notify_agent}
                        </span>

                        <Button
                          colorScheme="gray"
                          onClick={() => requestNotification()}
                        >
                          <i className="fas fa-sync " />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            <hr />
            <div className="my-2 w-full flex">
              <div className="w-8/12 self-center">
                <p className="text-sm ">การแจ้งเตือนผ่าน Line</p>
              </div>
              <div className="w-4/12 text-right">
                <LineNotifyComponent
                  clientId={
                    information?.line_client_id || LineNotifyConfig.clientId
                  }
                  redirectURL={
                    information?.line_redirect_url ||
                    LineNotifyConfig.redirectURL
                  }
                  state={me.employee_id}
                />
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}
