import { TASK_NOTIFY } from '../../actions/types';

// As same as Assigning Reducer
const initialState = {
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TASK_NOTIFY:
      return { isLoading: true };
    default:
      return state;
  }
}
