import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

import Spinners from '../../../../components/Loading/SpinnerLoading';
import * as actions from '../../../../redux/actions';
import { DEPART_INFO_TAB } from '../../../../config/WordUtil';
import DepartmentPresentTask from '../../../../components/Department/DepartmentPresentTask';
import TaskStatictic from '../../../../components/Department/TaskStatistic';

export default function HistoryOnEachDepartment() {
  // Variable Define
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams();
  const task = useSelector((state) => state.task);
  const department = useSelector((state) => state.department);

  const [selectedDepartment, setSelectedDepartment] = useState();
  const [pageTabStatus, setPageTabStatus] = useState(DEPART_INFO_TAB.PRESENT);

  useEffect(() => {
    const departmentId = param.id;
    const tempSelectedDepartment = _.find(
      department,
      (eachDepartment) => eachDepartment.department_id === departmentId,
    );
    if (tempSelectedDepartment) {
      setSelectedDepartment(tempSelectedDepartment);
    }
    return () => {};
  }, [param]);

  // Get Selected Task Data
  useEffect(() => {
    if (selectedDepartment) {
      dispatch(
        actions.taskAllFromDepartment(selectedDepartment.department_id),
      ).then(() => {
        setIsLoading(true);
      });
    }

    return () => {};
  }, [selectedDepartment]);

  // UI Rendering
  if (isLoading) {
    return (
      <div>
        <h2 className="text-gray-800 text-xl font-semibold py-1">
          สถานะงานในแผนก {selectedDepartment.department_name}
        </h2>

        <div className="py-4 md:py-6">
          <Link to="/">
            <Button variant="outline">กลับ</Button>
          </Link>
          <Button
            variant="outline"
            colorScheme={
              pageTabStatus === DEPART_INFO_TAB.PRESENT ? 'blue' : 'gray'
            }
            onClick={() => setPageTabStatus(DEPART_INFO_TAB.PRESENT)}
          >
            สถานะงานตอนนี้
          </Button>
          <Button
            variant="outline"
            colorScheme={
              pageTabStatus === DEPART_INFO_TAB.STATISTIC ? 'blue' : 'gray'
            }
            onClick={() => setPageTabStatus(DEPART_INFO_TAB.STATISTIC)}
          >
            สถิติงาน
          </Button>
        </div>
        <div className="p-4">
          {pageTabStatus === DEPART_INFO_TAB.PRESENT && (
            <DepartmentPresentTask allTask={task.arr} />
          )}
          {pageTabStatus === DEPART_INFO_TAB.STATISTIC && (
            <TaskStatictic
              allTask={task.arr}
              departmentId={selectedDepartment.department_id}
            />
          )}
        </div>
      </div>
    );
  }
  return <Spinners />;
}
