import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Avatar } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { CommentType } from '../../config/CommentType';
import * as actions from '../../redux/actions';

export default function CommentList({ allComments, taskId }) {
  const me = useSelector((state) => state.me);
  const orderdComments = _.orderBy(allComments, ['comment_timestamp', 'desc']);
  const dispatch = useDispatch();

  const deleteComment = (commentId) => {
    dispatch(actions.deleteComment(commentId)).then(() => {
      dispatch(actions.taskGet(taskId));
    });
  };

  return (
    <div className=" w-full p-3  mb-2 ">
      {!_.isEmpty(allComments) ? (
        <>
          {_.map(orderdComments, (commentItem, index) => (
            <div className="space-y-4 mb-4" key={index}>
              <div className="flex">
                <div className="flex-shrink-0 mr-3">
                  <Avatar
                    src={commentItem?.employee?.employee_image}
                    name={`${commentItem?.employee?.employee_first_name} ${commentItem?.employee?.employee_last_name}`}
                  />
                </div>
                <div className="flex-1 border rounded-lg px-4 py-2 sm:px-6 sm:py-4 leading-relaxed">
                  {commentItem?.comment_type === CommentType.CLOSE ? (
                    <div className="flex gap-2">
                      <div className="w-10">
                        <div className="rounded-full bg-green-500 p-2 text-center text-white">
                          <i className="fas fa-check" />
                        </div>
                      </div>
                      <div className="self-center">
                        <div>
                          <strong>
                            {`${commentItem?.employee?.employee_first_name} ${commentItem?.employee?.employee_last_name}  `}
                          </strong>
                          <span>ได้ปิดงานนี้แล้ว เมื่อวันที่ </span>

                          <span className=" text-gray-400">
                            {moment(commentItem?.comment_timestamp).format(
                              'lll',
                            )}
                          </span>
                        </div>
                        <div>
                          {/* prettier-ignore */}
                          <div className="flex flex-wrap gap-2 my-2">
                            {!_.isEmpty(commentItem['comment-images'])
                              ? _.map(commentItem['comment-images'], (image, imgIndex) => (
                                <img
                                  src={image?.image_string}
                                  key={imgIndex}
                                  className="max-h-48"
                                  alt="Comment"
                                />
                              ))
                              : ''}
                          </div>
                          <p className="text-sm">
                            {commentItem?.comment_message}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <strong>
                        {`${commentItem?.employee?.employee_first_name} ${commentItem?.employee?.employee_last_name}`}
                      </strong>
                      <span className="text-xs text-gray-400 ml-2">
                        {moment(commentItem?.comment_timestamp).format('lll')}
                        {commentItem?.employee?.employee_id ===
                          me?.employee_id && (
                          <button
                            type="button"
                            className="ml-2 text-xs text-gray-500 cursor-pointer hover:underline hover:text-gray-700"
                            onClick={() =>
                              deleteComment(commentItem?.comment_id)
                            }
                          >
                            ลบ
                          </button>
                        )}
                      </span>
                      {/* prettier-ignore */}
                      <div className="flex flex-wrap gap-2">
                        {!_.isEmpty(commentItem['comment-images'])
                          ? _.map(commentItem['comment-images'], (image, imgIndex) => (
                            <img
                              src={image?.image_string}
                              key={imgIndex}
                              className="max-h-48 my-1"
                              alt="Comment"
                            />
                          ))
                          : ''}
                      </div>
                      <p className="text-sm">{commentItem?.comment_message}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <> </>
      )}
    </div>
  );
}
