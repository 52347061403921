import React from 'react';
import PropTypes from 'prop-types';
import { Button, RequiredIndicator } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from '../../../redux/actions';

export default function CardEmployee({
  first_name,
  last_name,
  title,
  subtitle,
  email,
  phone_number,
  image,
  id,
  verify_status,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUserVerified = () => {
    const confirm = window.confirm('ยืนยันการยืนยันผู้ใช้งาน');
    if (confirm) {
      const commandPayload = { employee_verified: true };
      dispatch(actions.employeeEdit(id, commandPayload)).then(() => {
        dispatch(actions.employeeAll());
        history.push('/hrms/dashboard');
      });
    }
  };

  return (
    <>
      <div className="rounded rounded-t-lg overflow-hidden shadow-lg max-w-xs my-3 bg-white py-4">
        <div className="flex justify-center object-cover h-48 w-full">
          {image ? (
            <>
              <img
                alt="..."
                src={image}
                // className="shadow-md rounded-full h-auto align-middle border-none  my-2 max-w-150-px max-h-150-px"
              />
            </>
          ) : (
            <>
              {' '}
              <img
                alt="..."
                src={require('assets/img/favicon-eappsoft.png')}
                // className="shadow-md rounded-full h-auto align-middle border-none  my-2 max-w-150-px max-h-150-px"
              />
            </>
          )}
        </div>
        <div className="text-center px-3 pb-6 pt-2">
          <h3 className="text-black text-sm bold font-sans">
            {first_name + ' ' + last_name}
          </h3>
          <p className="mt-2  text-gray-500">{subtitle ? subtitle : <br />}</p>
          <p className="mt-2"> {title ? title : <br />}</p>
        </div>
        <hr />
        <div className="flex  flex-wrap justify-center my-2 text-grey-dark">
          {/* <div className="text-center  mr-3 border-r pr-3">
            <i className="fa fa-mail-bulk fa-1x"></i>
            <div className="text-xs">{email}</div>
          </div> */}
          <div className="text-center min-w-max-content">
            <i className="fa fa-phone"></i>
            <div className="text-xs">{phone_number}</div>
          </div>
        </div>
        {verify_status === false && (
          <div className="flex  flex-wrap justify-center my-2 text-grey-dark">
            {' '}
            <Button colorScheme="green" onClick={() => handleUserVerified()}>
              รับรองผู้ใช้งาน
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

CardEmployee.defaultProps = {
  first_name: '',
  last_name: '',
  title: '',
  subtitle: '',
  email: 'อีเมล',
  phone_number: 'เบอร์โทรศัพท์',
  image: null,
  verify_status: false,
};

CardEmployee.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  image: PropTypes.string,
  id: PropTypes.string,
  verify_status: PropTypes.bool,
};
