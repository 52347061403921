/* eslint-disable no-undef */
import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Table, Thead, Tr, Th, Td, Tbody, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import * as actions from '../../../../redux/actions';

export default function WorkstationList({ workStations }) {
  const dispatch = useDispatch();

  const handleDeleteWorkStation = (id) => {
    const confirm = window.confirm('ยืนยันการลบสถานที่ทำงาน');
    if (confirm) {
      dispatch(actions.workstationDelete(id));
    }
  };

  // prettier-ignore
  const workStationListing = () => _.map(workStations, (workstation, index) => (
    <Tr key={index}>
      <Td>{index + 1}</Td>
      <Td>{workstation.workstation_name}</Td>
      <Td>{workstation.workstation_location}</Td>
      <Td>
        <div className="flex gap-2">
          <Link to={`/hrms/workstation/edit/${workstation.workstation_id}`}>
            <Button size="sm" colorScheme="yellow">
              แก้ไข
            </Button>
          </Link>
          <Button
            size="sm"
            colorScheme="red"
            onClick={() => handleDeleteWorkStation(workstation.workstation_id)}
          >
            ลบ
          </Button>
        </div>
      </Td>
    </Tr>
  ));

  return (
    <div className=" flex-auto overflow-x-auto">
      <div>
        <Table
          size="sm"
          variant="striped"
          className="whitespace-no-wrap overflow-x-auto"
        >
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>ชือสถานีงาน</Th>
              <Th>ที่ตั้ง</Th>
              <Th>ดำเนินการ</Th>
            </Tr>
          </Thead>
          <Tbody>{workStationListing()}</Tbody>
        </Table>
      </div>
    </div>
  );
}
