const OtherData = {
  thai: 'อื่น ๆ',
  english: 'Others',
  id: 'OTHER',
};

const ALL_SELECT = 'ALL';

const MAIN_PAGE_TAB = {
  UNACCEPT: 'ยังไม่มีคนรับ',
  IN_PROGRESS: 'ดำเนินการ',
  SUCCESS: 'สำเร็จ',
};

const DEPART_INFO_TAB = {
  PRESENT: 'สถานะงานตอนนี้',
  STATISTIC: 'สถิติงาน',
};

const TIME_DURATION = {
  THIS_DAY: 'วันนี้',
  THIS_WEEK: 'สัปดาห์นี้',
  THIS_MONTH: 'เดือนนี้',
  THIS_QUATER: '3 เดือนที่ผ่านมา',
  THIS_YEAR: 'ปีที่ผ่านมา',
  ALL: 'ทั้งหมด',
};

export { OtherData, ALL_SELECT, MAIN_PAGE_TAB, DEPART_INFO_TAB, TIME_DURATION };
