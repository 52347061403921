/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../../../redux/actions';
import CardTaskData from '../../../../../components/common/Cards/Task/CardAcceptableTaskData';
import SpinnerLoading from '../../../../../components/Loading/SpinnerLoading';

export default function MobileAcceptedTask() {
  const allTask = useSelector((state) => state.task);
  const me = useSelector((state) => state.me);
  const [isLoading, setIsLoading] = useState(false);
  const [taskArray, setTaskArray] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.taskAllAssignedToMe(me.employee_id));

    return () => {};
  }, []);

  useEffect(() => {
    if (allTask.isLoading === true) {
      setTaskArray(allTask.arr);
      setIsLoading(true);
    }
    return () => {};
  }, [allTask]);

  return isLoading ? (
    <div>
      <div className="py-4 w-full">
        {!_.isEmpty(taskArray) ? (
          <>
            {' '}
            {_.map(
              _.orderBy(taskArray, 'task_issue_date', 'desc'),
              (task, index) => (
                <CardTaskData selectedTask={task} key={index} me={me} />
              ),
            )}
          </>
        ) : (
          <>
            <p>ยังไม่มีงานที่คุณรับ</p>
          </>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
