import { Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROLES } from '../../../../config/UserRoles';

export default function HRMSBottomNavigation() {
  const me = useSelector((state) => state.me);
  const adminNavBar = () => (
    <div className="lg:hidden fixed bottom-0 bg-white w-full h-18 print-disable">
      <div className="flex w-full justify-between p-2 gap-1">
        <Link
          to="/hrms/dashboard"
          className="w-full p-2 bg-gray-50  cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-users" />
          </h4>
          <p className="text-sm ">พนักงาน</p>
        </Link>

        <Link
          to={`/hrms/department/`}
          className="p-2 bg-gray-50 w-full cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-table" />
          </h4>
          <p className="text-sm">แผนก</p>
        </Link>

        <Link
          to="/"
          className="p-2 bg-gray-50 w-full cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-cog" />
          </h4>
          <p className="text-sm">จัดการ</p>
        </Link>
      </div>
    </div>
  );

  const superUserNavItem = () => (
    <div className="lg:hidden fixed bottom-0 bg-white w-full h-18 print-disable">
      <div className="flex w-full justify-between p-2 gap-1">
        <Link
          to="/hrms/dashboard"
          className="w-full p-2 bg-gray-50  cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-user" />
          </h4>
          <p className="text-sm ">พนักงาน</p>
        </Link>

        <Link
          to={`/hrms/department/`}
          className="p-2 bg-gray-50 w-full cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-users" />
          </h4>
          <p className="text-sm">แผนก</p>
        </Link>

        <Link
          to={`/hrms/management`}
          className="p-2 bg-gray-50 w-full cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-key" />
          </h4>
          <p className="text-sm">Access</p>
        </Link>

        <Link
          to="/"
          className="p-2 bg-gray-50 w-full cursor-pointer text-center"
        >
          <h4 className="text-lg">
            <i className="fas fa-cog" />
          </h4>
          <p className="text-sm">จัดการ</p>
        </Link>
      </div>
    </div>
  );
  return (
    <div>
      {me.employee_role === ROLES.SUPER_USER
        ? superUserNavItem()
        : adminNavBar()}
    </div>
  );
}
