import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@chakra-ui/react';

export default function NotifyComponent({ clientId, redirectURL, state }) {
  const me = useSelector((state) => state.me);
  return (
    <div>
      <a
        href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURL}&scope=notify&state=${state}`}
      >
        {me.employee_line_token ? (
          <Button colorScheme="gray"> เปลี่ยนบัญชี </Button>
        ) : (
          <Button colorScheme="gray"> รับแจ้งเตือน </Button>
        )}
      </a>
    </div>
  );
}
