import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { firebaseAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import * as UserInfo from 'config/UserInfo';
import * as actions from '../../redux/actions';

export default function Register() {
  const { register, handleSubmit, errors } = useForm();
  const { handleSignupByHRMS } = useContext(firebaseAuth);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async (data, e) => {
    if (data.password === data.confirm_password) {
      e.preventDefault();
      console.log(data);
      await handleSignupByHRMS(data.employee_email, data.password, data);
      history.push('/');
    } else {
      alert('รหัสผ่านไม่ตรงกัน');
    }
  };

  const [selectedDepartment, setSelectedDepartment] = useState();
  const position = useSelector((state) => state.position);
  const department = useSelector((state) => state.department);

  useEffect(() => {
    dispatch(actions.positionAll());
    dispatch(actions.departmentAll());
    return () => {};
  }, []);

  const genDepartmentOption = () =>
    _.map(department, (_department) => (
      <option key={_department.department_id} value={_department.department_id}>
        {_department.department_name}
      </option>
    ));

  const genPositionOption = () =>
    _.map(
      _.filter(
        position,
        (_eachPosition) => _eachPosition.department_id === selectedDepartment,
      ),
      (_position) => (
        <option key={_position.position_id} value={_position.position_id}>
          {_position.position_name}
        </option>
      ),
    );

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h2 className="text-gray-600 text-sm font-bold">
                    ลงทะเบียนด้วยอีเมล
                  </h2>
                </div>
                <hr className="mt-6 border-b-1 border-gray-400" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    ข้อมูลพนักงาน
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          อีเมล{' '}
                        </label>
                        <input
                          name="employee_email"
                          type="email"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="example@email.com"
                        />
                      </div>
                    </div>
                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          รหัสผ่าน
                        </label>
                        <input
                          name="password"
                          type="password"
                          required
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="รหัสผ่าน"
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ยืนยันรหัสผ่าน
                        </label>
                        <input
                          name="confirm_password"
                          type="password"
                          required
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="ยืนยันรหัสผ่าน"
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ชื่อ
                        </label>
                        <input
                          name="employee_first_name"
                          type="text"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="ชื่อ"
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          นามสกุล
                        </label>
                        <input
                          name="employee_last_name"
                          type="text"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="นามสกุล"
                        />
                      </div>
                    </div>
                    <div className="w-full px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          เพศ
                        </label>

                        <select
                          name="employee_gender"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          <option value="">เลือกเพศ</option>
                          <option value={UserInfo.GENDER.MALE}>
                            {UserInfo.GENDER.MALE}
                          </option>
                          <option value={UserInfo.GENDER.FEMALE}>
                            {UserInfo.GENDER.FEMALE}
                          </option>
                          <option value={UserInfo.GENDER.OTHERS}>
                            {UserInfo.GENDER.OTHERS}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="w-full px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          หมายเลขโทรศัพท์
                        </label>
                        <input
                          name="employee_phone_number"
                          type="tel"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="081xxxxxxx"
                        />
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ที่อยู่
                        </label>
                        <textarea
                          name="employee_address"
                          required
                          ref={register}
                          rows="5"
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="ที่อยู่ "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full  px-4">
                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        แผนก
                      </label>
                      <select
                        name="department_id"
                        required
                        ref={register}
                        // prettier-ignore
                        onChange={(event) => setSelectedDepartment(event.target.value)}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      >
                        <option value="">เลือกแผนก</option>
                        {genDepartmentOption()}
                      </select>
                    </div>
                  </div>

                  <div className="w-full  px-4">
                    <div className="relative w-full  mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        ตำแหน่ง
                      </label>
                      <select
                        name="position_id"
                        required
                        ref={register}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      >
                        <option value="">เลือกตำแหน่ง</option>
                        {genPositionOption()}
                      </select>
                    </div>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      สร้างบัญชี
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2"></div>
              <div className="w-1/2 text-right">
                <Link
                  to="/auth/login"
                  className="text-gray-800 bg-white opacity-60 px-2 rounded-md"
                >
                  <small>มีบัญชีอยู่แล้ว</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
