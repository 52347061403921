import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  HStack,
  Box,
} from '@chakra-ui/react';

import _ from 'lodash';
import * as actions from '../../../../redux/actions';

export default function CommonWorkByDepartment({ works }) {
  const dispatch = useDispatch();
  const { id } = useParams(); // ID as Department ID

  const handleDelete = (workId) => {
    dispatch(actions.commonWorkDelete(workId)).then(() => {
      dispatch(actions.commonWorkAllByDepartment(id)).then(() => {});
    });
  };

  // prettier-ignore
  const genTd = () => _.map(works, (eachWork) => (
    <Tr key={eachWork.work_id}>
      <Td>{eachWork.work_name}</Td>
      {/* <Td>{eachWork.position_salary}</Td> */}
      <Td>
        <HStack spacing={2}>
        <Box>
              <Link
                to={`/hrms/department/${id}/commonwork/edit/${eachWork.work_id}`}
              >
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>
          <Button
            colorScheme="red"
            onClick={() => {
              handleDelete(eachWork.work_id);
            }}
          >
            ลบ
          </Button>
        </HStack>
      </Td>
    </Tr>
  ));

  return (
    <div className=" rounded rounded-t-lg bg-white p-4 shadow-lg max-h-screen overflow-y-auto">
      <Table variant="striped" size="lg">
        <Thead>
          <Tr>
            <Th>งาน</Th>
            {/* <Th>รายละเอียด</Th> */}
            <Th>ดำเนินการ</Th>
          </Tr>
        </Thead>
        <Tbody>{genTd()}</Tbody>
      </Table>
    </div>
  );
}
