import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Context
import { firebaseAuth } from '../../contexts/AuthContext';
// components

import AdminNavbar from 'components/common/Navbars/AdminNavbar.js';
import Sidebar from 'components/common/Sidebar/Sidebar-WMS/Sidebar-Dashboard';
import HeaderStats from 'components/common/Headers/HeaderStats.js';
import FooterAdmin from 'components/common/Footers/FooterAdmin';
import Spinners from 'components/Loading/SpinnerLoading';
import BottomNavigation from '../../components/common/Navbars/BottomNav/BottomNavbar';
import * as actions from '../../redux/actions';

// views

import IndexDashboard from '../../views/wms/WorkDashboard/index';
import WorkAssignment from '../../views/wms/WorkDashboard/CreateTask/CreateTask';
import MyWorkAssignStatus from '../../views/wms/WorkDashboard/ViewTaskList/ViewAssignedTask';
import ViewTaskStatus from '../../views/wms/WorkDashboard/ViewTask/ViewTask';
import ProfileAndMyWork from '../../views/wms/WorkDashboard/UserProfile/UserProfile';
import EditTask from '../../views/wms/WorkDashboard/EditTask/EditTask';
import MobileTaskView from '../../views/wms/WorkDashboard/ViewTaskList/MobileViewAllTask';
import DepartmentTask from '../../views/wms/WorkDashboard/DepartmentTask/DepartmentTask';
import LineNotifyRegister from '../../views/wms/WorkDashboard/LineNotify/LineNotifyRedirect';
import PersonalStatistic from '../../views/wms/WorkDashboard/UserProfile/UserTaskProfile';
import EditProfile from '../../views/wms/WorkDashboard/UserProfile/EditProfile';
import ReportAndComment from '../../views/wms/WorkDashboard/UserProfile/ReportAndComment';

export default function WorkDashbaordLayout() {
  const dispatch = useDispatch();
  const { handleSignout } = useContext(firebaseAuth);
  const me = useSelector((state) => state.me);
  const history = useHistory();

  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  const [isLoading, setIsLoading] = useState(false);

  // ข้อมูลสำหรับ authorize จาก  local_storage
  const storageRemember = localStorage.getItem('me');

  useEffect(() => {
    console.log('WMS-Dashboard Module is Active');
    dispatch(actions.informationGet());

    dispatch(actions.meGet(storageRemember)).then(() => {
      // เอาไว้ใช้กับ Tab แต่ละ Department แต่ตอนนี้เรา Disable Tab ตรงนั้นไว้
      // เนื่องจากว่าเราสามารถกรองในตัวของรายการงานได้แล้ว ว่าให้ใครได้เห็นส่วนไหนได้
      dispatch(actions.departmentAll()).then(() => {
        setIsLoading(true);
      });
    });

    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me) {
      if (me?.employee_verified === false) {
        console.log('User is not verify');
        alert('ขออภัย User ตัวนี้อยู่ในสถานะ Inactive กรณีติดต่อผู้ดูแลระบบ');
        onSignOut();
      }
    }

    return () => {};
  }, [me]);

  if (isLoading === true) {
    return (
      <>
        <Sidebar />
        <div className="relative lg:ml-64 bg-gray-200">
          <div className=" py-0 md:px-10 mx-auto w-full min-h-screen ">
            <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-screen">
              <div className="rounded-t mb-0 px-4 pt-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-gray-500 text-xs font-semibold hidden md:block">
                      รายการงาน
                    </h6>
                  </div>
                </div>
              </div>
              <div className="px-2 md:px-4">
                <Switch>
                  {/* <Route
                    path="/wms/dashboard/department/:id"
                    exact
                    component={DepartmentDashboard}
                  /> */}

                  <Route
                    path="/wms/dashboard/profile"
                    exact
                    component={ProfileAndMyWork}
                  />

                  <Route
                    path="/wms/dashboard/assign"
                    exact
                    component={WorkAssignment}
                  />

                  <Route
                    path="/wms/dashboard/edit/:id"
                    exact
                    component={EditTask}
                  />

                  <Route
                    path="/wms/dashboard/view/:id"
                    exact
                    component={ViewTaskStatus}
                  />

                  {/** For Mobile User only it will not define in sidebar but it still in BottomNavigation */}
                  <Route
                    path="/wms/dashboard/mobileview"
                    exact
                    component={MobileTaskView}
                  />

                  {/** Pretty URL For Mobile view incase of use */}
                  <Route
                    path="/wms/dashboard/myassign"
                    exact
                    component={MobileTaskView}
                  />

                  <Route
                    path="/wms/dashboard/departmentwork"
                    exact
                    component={DepartmentTask}
                  />

                  <Route
                    path="/wms/dashboard/notify"
                    exact
                    component={LineNotifyRegister}
                  />

                  <Route
                    path="/wms/dashboard/personalstatistic"
                    exact
                    component={PersonalStatistic}
                  />

                  <Route
                    path="/wms/dashboard/profile/edit"
                    exact
                    component={EditProfile}
                  />

                  <Route
                    path="/wms/dashboard/reportandcomment"
                    exact
                    component={ReportAndComment}
                  />

                  <Route
                    path="/wms/dashboard"
                    exact
                    component={IndexDashboard}
                  />

                  <Redirect from="/wms/dashboard/*" to="/wms/dashboard/" />
                </Switch>
              </div>
              <div />
            </div>
          </div>
          <BottomNavigation />
          <FooterAdmin />
        </div>
      </>
    );
  }

  return <Spinners />;
}
