import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import IndexNavbar from '../components/common/Navbars/IndexNavbar';
import Footer from '../components/common/Footers/Footer';
import * as actions from '../redux/actions';
import { ROLES } from '../config/UserRoles';

export default function Index() {
  const me = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.meGet());
    dispatch(actions.informationGet());
    return () => {};
  }, []);

  useEffect(() => {
    if (me) {
      // prettier-ignore
      const adminPermission = me?.employee_role === ROLES.ADMIN
        || me?.employee_role === ROLES.SUPER_USER;
      if (!adminPermission) {
        history.push('/wms/dashboard');
      }
    }

    return () => {};
  }, [me]);

  return (
    <>
      <IndexNavbar fixed />
      <section className="container mx-auto items-center flex flex-wrap min-h-screen">
        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-20">
          <div className="justify-start flex flex-wrap">
            <div className="my-4 w-full lg:w-6/12 px-4">
              <Link to="/wms/dashboard">
                <div className="bg-blue-500 shadow-lg rounded-lg text-center p-8  min-h-screen-10">
                  <i className="fas fa-file-alt fa-3x text-white" />
                  <p className="text-lg text-semibold text-white mt-4 ">
                    รับ/แจ้งงาน
                  </p>
                </div>
              </Link>
              <Link to="/wms/history">
                <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8 mt-8 min-h-screen-10">
                  <i className="fas fa-chart-line fa-3x text-white" />
                  <p className="text-lg text-semibold text-white mt-4  ">
                    {' '}
                    รายงาน และ ประวัติ
                  </p>
                </div>
              </Link>
            </div>

            <div className="my-4 w-full lg:w-6/12 px-4">
              <Link to="/hrms/dashboard">
                <div className=" bg-gray-800 shadow-lg rounded-lg text-center p-8 min-h-screen-10">
                  <i className="fas fa-cogs fa-3x text-white" />
                  <p className="text-lg text-semibold text-white mt-4">
                    จัดการระบบ
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
