import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, HStack } from '@chakra-ui/react';
import _ from 'lodash';

import CardInfo from '../../../components/common/Cards/CardInfo';
import SpinnerLoading from '../../../components/Loading/SpinnerLoading';
import PositionByDepartmentLists from './components/PositionByDepartmentLists';
import * as actions from '../../../redux/actions/index';
import CommonWorkByDepartment from './components/CommonWorkByDepartment';
import { ROLES } from '../../../config/UserRoles';

export default function DetailDepartment() {
  const history = useHistory();
  const { id } = useParams();
  const me = useSelector((state) => state.me);
  const department = useSelector((state) => state.department);
  const position = useSelector((state) => state.position);
  const commonWorks = useSelector((state) => state.commonWork);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.departmentGet(id)).then(() => {
      dispatch(actions.positionAllByDepartment(id)).then(() => {
        dispatch(actions.commonWorkAllByDepartment(id)).then(() => {
          setIsLoading(true);
        });
      });
    });
    return () => {};
  }, []);

  const onDelete = () => {
    dispatch(actions.departmentDelete(id)).then(() => {
      console.log('ลบแผนกสำเร็จ กำลังลบตำแหน่ง');
      dispatch(actions.positionDeleteByDepartment(id)).then(() => {
        console.log('ลบตำแหน่งสำเร็จ');
        history.push('/hrms/department');
      });
    });
  };
  // console.log(isLoading);
  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        {' '}
        รายละเอียดแผนก
      </h2>

      <div className="flex py-6 justify-between">
        <div>
          <Link to="/hrms/department">
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div>
          <HStack spacing={2}>
            <Box>
              <Link to={`/hrms/department/edit/${id}`}>
                {' '}
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>

            <Button
              colorScheme="red"
              disabled={me.employee_role === ROLES.SUPER_USER ? false : true}
              onClick={() => {
                const confirm = window.confirm('ยืนยันการลบข้อมูลแผนก');
                if (confirm) onDelete();
              }}
            >
              ลบ
            </Button>
          </HStack>
        </div>
      </div>

      <div className="p-4">
        {/* <div className="flex content-center items-center justify-center h-full"> */}
        <div className="w-full  ">
          <CardInfo
            title={department.department_name}
            subtitle={department.department_detail}
            icon="fa fa-building"
          />
        </div>
        <div className="my-6 ">
          <div className="flex flex-wrap  justify-center">
            <div className="w-full lg:w-6/12 lg:px-2 ">
              <div className="my-4 flex gap-2 ">
                <Link to={`/hrms/department/${id}/position/create`}>
                  <Button colorScheme="blue">เพิ่มตำแหน่ง</Button>
                </Link>
              </div>
              {_.size(position) ? (
                <PositionByDepartmentLists position={position} />
              ) : (
                <div className="flex content-center items-center justify-center  rounded rounded-t-lg bg-white p-4">
                  ไม่มีข้อมูลและรายละเอียดตำแหน่งในแผนก
                </div>
              )}
            </div>

            <div className="w-full lg:w-6/12 lg:px-2">
              <div className="my-4 flex gap-2 ">
                <Link to={`/hrms/department/${id}/commonwork/create`}>
                  <Button colorScheme="blue">เพิ่มงาน</Button>
                </Link>
              </div>
              {_.size(commonWorks) ? (
                <CommonWorkByDepartment works={commonWorks} />
              ) : (
                <div className="flex content-center items-center justify-center  rounded rounded-t-lg bg-white p-4">
                  ไม่มีข้อมูลงานทั่วไปในแผนก
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
