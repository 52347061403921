import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAtuFu7UpUulhWf_cGHEFWRy3kcdUufnbk',
  authDomain: 'eapp-da9e1.firebaseapp.com',
  databaseURL: 'https://eapp-da9e1.firebaseio.com',
  projectId: 'eapp-da9e1',
  storageBucket: 'eapp-da9e1.appspot.com',
  messagingSenderId: '103300656488',
  appId: '1:103300656488:web:a0ffb640a149367f413add',
  measurementId: 'G-J3W87J558B',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const app = firebase.app();

export const auth = firebase.auth();
export const storage = firebase.storage();

export const messaging =
  navigator.platform !== 'iPhone' &&
  navigator.platform !== 'iPad' &&
  navigator.platform !== 'iPod'
    ? firebase.messaging()
    : undefined;

// prettier-ignore
export const vapidKey = 'BFt4G6-1dyEvgGSSmOxELqI6rBBLGhQR90h2XeHgCzyVUFBDRUXvkTnnXwVVenH_CXCfzdwYDIZzp2ZjZAFCp7I';

export const signInAuthProvider = [
  {
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  },
  {
    provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    scopes: ['public_profile', 'email'],
  },
];

console.log(app.name ? 'Firebase Activated!' : 'Firebase not working :(');

export default firebase;
