import {
  WORKSTATION_ALL,
  WORKSTATION_DELETE,
  WORKSTATION_GET,
  WORKSTATION_POST,
  WORKSTATION_PUT,
} from '../../actions/types';

const initialState = {
  employee: null,
  isLoading: false,
};

// arr: [...action.payload],
// isLoading: true,

export default function WorkstationReducer(state = initialState, action) {
  switch (action.type) {
    case WORKSTATION_ALL:
      return { arr: [...action.payload], isLoading: true };
    case WORKSTATION_GET:
      return { ...action.payload, isLoading: true };
    case WORKSTATION_POST:
      return { ...action.payload, isLoading: true };
    case WORKSTATION_PUT:
      return { ...action.payload, isLoading: true };
    case WORKSTATION_DELETE:
      return { isLoading: true };
    default:
      return state;
  }
}
