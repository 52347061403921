import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';

import * as actions from '../../../redux/actions/index';
import LogTypes from '../../../config/LogTypes';

export default function CreateDepartment() {
  const me = useSelector((state) => state.me);
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const onSubmit = (data, e) => {
    dispatch(
      actions.logCreate(
        me.employee_id,
        LogTypes.SYSTEM_EDIT,
        `เพิ่มแผนกใหม่ ${data.department_name}`,
      ),
    );
    dispatch(actions.departmentPost(data)).then(() => {
      console.log('เพิ่มแผนกสำเร็จ');
      history.push('/hrms/department');
    });
  };
  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">เพิ่มแผนก</h2>

      <div className="py-6">
        <Link to="/hrms/department">
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {' '}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อแผนก
                </label>
                <input
                  name="department_name"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อแผนก"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รายละเอียด
                </label>
                <textarea
                  name="department_detail"
                  type="textfield"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="รายละเอียด"
                />
                <div className="text-center mt-6">
                  <Button isFullWidth colorScheme="blue" type="submit">
                    เพิ่ม
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
