/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'object-hash';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import ImageUpload from '../../../components/ImageUpload/ImageUpload';
import { firebaseAuth } from '../../../contexts/AuthContext';
import * as UserInfo from '../../../config/UserInfo';
import { uploadImageFile } from '../../../util/image.functions';
import * as actions from '../../../redux/actions';
import { ROLES } from '../../../config/UserRoles';
import LogTypes from '../../../config/LogTypes';

export default function CreateEmployee() {
  const { register, handleSubmit, reset } = useForm();
  const [image, setImage] = useState([]);
  const { handleSignupByHRMS } = useContext(firebaseAuth);
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const position = useSelector((state) => state.position);
  const department = useSelector((state) => state.department);
  const [selectedDepartment, setSelectedDepartment] = useState(
    me.employee_role === ROLES.SUPER_USER ? undefined : me.department_id,
  );

  useEffect(() => {
    dispatch(actions.positionAll());
    dispatch(actions.departmentAll());
    return () => {};
  }, []);

  const onSubmit = async (data, e) => {
    if (data.password === data.confirm_password && _.size(image)) {
      const filename = hash({ image, date: new Date() });
      uploadImageFile(filename, image[0].data_url).then(async (url) => {
        console.log(`URL : ${url}`);
        data.employee_image = url;
        data.employee_verified = true; // เฉพาะลงทะเบียนผ่าน HRMS ให้มีสถานะ Verified ทันที

        e.preventDefault();
        console.log(data);
        dispatch(
          actions.logCreate(
            me.employee_id,
            LogTypes.USER_CREATE,
            `สร้าง User ${data.employee_first_name}  `,
          ),
        );
        await handleSignupByHRMS(data.employee_email, data.password, data);
        window.alert('ลงทะเบียนสำเร็จ');
        reset();
        setImage([]);
      });
    } else if (data.password === data.confirm_password) {
      dispatch(
        actions.logCreate(
          me.employee_id,
          LogTypes.USER_CREATE,
          `สร้าง User ${data.employee_first_name}  `,
        ),
      );
      await handleSignupByHRMS(data.employee_email, data.password, data);
      window.alert('ลงทะเบียนสำเร็จ');
      reset();
    } else {
      window.alert('รหัสผ่านไม่ตรงกัน');
    }
  };

  const genDepartmentOption = () =>
    _.map(department, (_department) => (
      <option key={_department.department_id} value={_department.department_id}>
        {_department.department_name}
      </option>
    ));

  const genPositionOption = () =>
    _.map(
      _.filter(
        position,
        (_eachPosition) => _eachPosition.department_id === selectedDepartment,
      ),
      (_position) => (
        <option key={_position.position_id} value={_position.position_id}>
          {_position.position_name}
        </option>
      ),
    );

  return (
    <>
      <div>
        <div>
          <div>
            <div>
              <div>
                <h2 className="text-gray-800 text-xl font-semibold py-1">
                  เพิ่มพนักงาน
                </h2>
                <div className="flex justify-between py-6">
                  <Link to="/hrms/dashboard">
                    <Button variant="outline">กลับ</Button>
                  </Link>
                </div>
              </div>
              <div className="flex content-center items-center justify-center h-full mb-12">
                <div className="w-full lg:w-6/12 px-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          รูปภาพประจำตัว
                        </label>
                        <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                          <ImageUpload
                            images={image}
                            setImages={setImage}
                            preview_size="100px"
                          />
                        </div>
                      </div>

                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            อีเมล
                          </label>
                          <input
                            name="employee_email"
                            type="email"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="example@email.com"
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รหัสผ่าน
                          </label>
                          <input
                            name="password"
                            type="password"
                            required
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="รหัสผ่านอย่างน้อย 6 ตัวอักษร"
                            ref={register}
                          />
                        </div>
                      </div>
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ยืนยันรหัสผ่าน
                          </label>
                          <input
                            name="confirm_password"
                            type="password"
                            required
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ยืนยันรหัสผ่าน"
                            ref={register}
                          />
                        </div>
                      </div>

                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อ
                          </label>
                          <input
                            name="employee_first_name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ชื่อ"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            นามสกุล
                          </label>
                          <input
                            name="employee_last_name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="นามสกุล"
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            เพศ
                          </label>

                          <select
                            name="employee_gender"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value={UserInfo.GENDER.MALE}>
                              {UserInfo.GENDER.MALE}
                            </option>
                            <option value={UserInfo.GENDER.FEMALE}>
                              {UserInfo.GENDER.FEMALE}
                            </option>
                            <option value={UserInfo.GENDER.OTHERS}>
                              {UserInfo.GENDER.OTHERS}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            เบอร์โทรศัพท์
                          </label>

                          <input
                            name="employee_phone_number"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="เบอร์โทรศัพท์"
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ที่อยู่
                          </label>
                          <textarea
                            name="employee_address"
                            required
                            ref={register}
                            rows="5"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ที่อยู่"
                          />
                        </div>
                      </div>
                    </div>

                    {me.employee_role === ROLES.SUPER_USER ? (
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            แผนก
                          </label>
                          <select
                            name="department_id"
                            required
                            ref={register}
                            // prettier-ignore
                            onChange={(event) => setSelectedDepartment(event.target.value)}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            {genDepartmentOption()}
                          </select>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            แผนก
                          </label>
                          <select
                            name="department_id"
                            required
                            ref={register}
                            disabled={
                              me.employee_role === ROLES.SUPER_USER
                                ? false
                                : true
                            }
                            value={me.department_id}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            {genDepartmentOption()}
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ตำแหน่ง
                        </label>
                        <select
                          name="position_id"
                          required
                          ref={register}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          {genPositionOption()}
                        </select>
                      </div>
                    </div>

                    <div className="text-center px-4 gap-0 py-3">
                      <Button
                        isFullWidth
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                      >
                        สร้างบัญชี
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
