import { Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROLES } from '../../../../config/UserRoles';

export default function BottomNavigation() {
  const me = useSelector((state) => state.me);

  const userNavBarItem = () => (
    <div className="flex w-full justify-between p-2 gap-1">
      <Link
        to="/wms/dashboard"
        className="w-full p-2 bg-gray-50  cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-home" />
        </h4>
        <p className="text-sm ">หน้าแรก</p>
      </Link>

      <Link
        to="/wms/dashboard/assign"
        className="p-2 bg-gray-50 w-full cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-plus-square" />
        </h4>
        <p className="text-sm">เพิ่มงาน</p>
      </Link>

      <Link
        to="/wms/dashboard/mobileview"
        className="p-2 bg-gray-50 w-full cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-file-alt" />
        </h4>
        <p className="text-sm">งาน</p>
      </Link>

      <Link
        to="/wms/dashboard/profile"
        className="p-2 bg-gray-50 w-full cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-user" />
        </h4>
        <p className="text-sm">โปรไฟล์</p>
      </Link>
    </div>
  );

  const adminNavbarItem = () => (
    <div className="flex w-full justify-between p-2 gap-1">
      <Link
        to="/wms/dashboard"
        className="w-full p-2 bg-gray-50  cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-home" />
        </h4>
        <p className="text-sm ">หน้าแรก</p>
      </Link>

      <Link
        to="/wms/dashboard/assign"
        className="p-2 bg-gray-50 w-full cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-plus-square" />
        </h4>
        <p className="text-sm">เพิ่มงาน</p>
      </Link>

      <Link
        to="/wms/dashboard/mobileview"
        className="p-2 bg-gray-50 w-full cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-file-alt" />
        </h4>
        <p className="text-sm">งาน</p>
      </Link>

      <Link
        to="/wms/dashboard/profile"
        className="p-2 bg-gray-50 w-full cursor-pointer text-center"
      >
        <h4 className="text-lg">
          <i className="fas fa-user" />
        </h4>
        <p className="text-sm">โปรไฟล์</p>
      </Link>

      <Link to="/" className="p-2 bg-gray-50 w-full cursor-pointer text-center">
        <h4 className="text-lg">
          <i className="fas fa-cog" />
        </h4>
        <p className="text-sm">จัดการ</p>
      </Link>
    </div>
  );

  return (
    <div className="lg:hidden fixed bottom-0 bg-white w-full h-18 print-disable">
      {me && me.employee_role === ROLES.USER
        ? userNavBarItem()
        : adminNavbarItem()}
    </div>
  );
}
