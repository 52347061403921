import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

// components
import * as actions from '../../../redux/actions';
import LogList from './components/LogList';
import SpinnerLoading from 'components/Loading/SpinnerLoading';

export default function ManagmentUser() {
  const logs = useSelector((state) => state.log);
  const [logData, setLogData] = useState(logs?.arr);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState([
    moment().subtract(1, 'days').toDate(),
    moment().toDate(),
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.logFetch(
        moment().subtract(1, 'days').format('YYYYMMDD'),
        moment().format('YYYYMMDD'),
      ),
    ).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  useEffect(() => {
    setLogData(logs.arr);
    return () => {};
  }, [logs]);

  const updateTimeRange = () => {
    setIsLoading(false);
    dispatch(
      actions.logFetch(
        moment(selectedRange[0]).format('YYYYMMDD'),
        moment(selectedRange[1]).format('YYYYMMDD'),
      ),
    ).then(() => {
      setIsLoading(true);
    });
  };

  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        ประวัติการใช้งาน
      </h2>
      <div className="flex justify-end"></div>
      <div className="py-4 w-full">
        <div className="my-2 flex gap-2">
          <DateRangePicker
            value={selectedRange}
            onChange={setSelectedRange}
            clearIcon={null}
          />
          <Button
            size="sm"
            colorScheme="blue"
            onClick={() => updateTimeRange()}
          >
            อัพเดท
          </Button>
        </div>
        {_.size(logData) ? (
          <div>
            <LogList logs={logData} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
