import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function AllActiveWorkChart({ allDepartment, allTask }) {
  const [dataStack, setDataStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  // Format Data
  useEffect(() => {
    // dataStack at index i refer to department as index i
    _.map(allDepartment, (department) => {
      const tempLabelStack = labelStack;
      tempLabelStack.push(department.department_name);
      setLabelStack(tempLabelStack);

      // Find Task Amount
      let taskAmount = 0;
      _.map(allTask, (task) => {
        if (task.task_department === department.department_id) {
          taskAmount += 1;
        }
      });

      // setting new dataStack into the state
      const tempDataStack = dataStack;
      tempDataStack.push(taskAmount);
      setDataStack(tempDataStack);
    });
    return () => {};
  }, [allTask]);

  const options = {
    labels: labelStack,
  };
  return (
    <div>
      <ReactApexChart
        options={options}
        series={dataStack}
        type="donut"
        height="400"
      />
    </div>
  );
}
