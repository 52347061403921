import { INFORMATION_GET, INFORMATION_PUT } from '../../actions/types';

// As same as Assigning Reducer
const initialState = {
  information: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INFORMATION_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case INFORMATION_PUT:
      return { isLoading: false };
    default:
      return state;
  }
}
