import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react';

import _ from 'lodash';
import * as actions from '../../../../redux/actions';

export default function PositionByDepartmentLists({ position }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handdleDelete = (departmentId) => {
    dispatch(actions.positionDelete(departmentId)).then(() => {
      dispatch(actions.positionAllByDepartment(id)).then(() => {
        // alert("ลบตำแหน่งสำเร็จ");
      });
    });
  };

  const genTd = () =>
    _.map(position, (_position) => (
      <Tr key={_position.position_id}>
        <Td>{_position.position_name}</Td>
        {/* <Td>{_position.position_salary}</Td> */}
        <Td>
          <HStack spacing={2}>
            <Box>
              <Link
                to={`/hrms/department/${id}/position/edit/${_position.position_id}`}
              >
                {' '}
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>

            <Button
              colorScheme="red"
              onClick={() => {
                handdleDelete(_position.position_id);
              }}
            >
              ลบ
            </Button>
          </HStack>
        </Td>
      </Tr>
    ));

  return (
    <div className=" rounded rounded-t-lg bg-white p-4 shadow-lg max-h-screen overflow-y-auto">
      <Table variant="striped" size="lg">
        <Thead>
          <Tr>
            <Th>ตำแหน่ง</Th>
            {/* <Th>รายละเอียด</Th> */}
            <Th>ดำเนินการ</Th>
          </Tr>
        </Thead>
        <Tbody>{genTd()}</Tbody>
      </Table>
    </div>
  );
}
