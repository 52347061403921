/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { firebaseAuth } from 'contexts/AuthContext';
import { useForm } from 'react-hook-form';
import * as UserInfo from 'config/UserInfo';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import hash from 'object-hash';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';
import { uploadImageFile } from '../../../../util/image.functions';
import * as actions from '../../../../redux/actions';

export default function EditProfile() {
  const { register, handleSubmit, reset } = useForm();
  const [image, setImage] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const position = useSelector((state) => state.position);
  const department = useSelector((state) => state.department);
  const employee = useSelector((state) => state.employee.arr);
  const me = useSelector((state) => state.me);
  const [positionSelect, setPositionSelect] = useState();
  const [departmentSelect, setDepartmentSelect] = useState();
  const [employeeSelect, setEmployeeSelect] = useState(null);
  const [genderSelect, setGenderSelect] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    dispatch(actions.positionAll());
    dispatch(actions.departmentAll());
    dispatch(actions.employeeAll());

    return () => {};
  }, []);

  const findEmployee = () => {
    const findEmployeeSelect = _.find(employee, (_employee) => {
      if (_employee != null && _employee != undefined) {
        return _employee.employee_id === me.employee_id;
      }
    });
    if (findEmployeeSelect !== undefined && count === 0) {
      setEmployeeSelect(findEmployeeSelect);
      setGenderSelect(findEmployeeSelect.employee_gender);
      setDepartmentSelect(findEmployeeSelect.department_id);
      setPositionSelect(findEmployeeSelect.position_id);
      setCount(1);
    }
  };
  const onSubmit = async (data, e) => {
    const confirm = window.confirm('ยืนยันการแก้ไขข้อมูลพนักงาน');
    if (confirm) {
      if (image === []) {
        dispatch(actions.employeeEdit(employeeSelect.employee_id, data)).then(
          async () => {
            // alert('แก้ไขสำเร็จ');
            dispatch(actions.employeeGet(employeeSelect.employee_id));
            history.push('/hrms/dashboard');
          },
        );
      } else if (image && image[0]) {
        const filename = hash({ image, date: new Date() });
        uploadImageFile(filename, image[0]?.data_url).then(async (url) => {
          console.log(`URL : ${url}`);

          data.employee_image = url;
          e.preventDefault();
          // console.log(data);
          dispatch(actions.employeeEdit(employeeSelect.employee_id, data)).then(
            async () => {
              // alert('แก้ไขสำเร็จ');
              dispatch(actions.employeeGet(employeeSelect.employee_id));
              history.push('/wms/dashboard/profile');
            },
          );
          reset();
          setImage([]);
        });
      } else {
        dispatch(actions.employeeEdit(employeeSelect.employee_id, data)).then(
          async () => {
            // alert('แก้ไขสำเร็จ');
            dispatch(actions.employeeGet(employeeSelect.employee_id));
            history.push('/wms/dashboard/profile');
          },
        );
        reset();
      }
    }
  };

  const getDepartmentOption = () =>
    _.map(department, (_department) => (
      <option key={_department.department_id} value={_department.department_id}>
        {_department.department_name}
      </option>
    ));

  // TODO: Filter Position upon Department
  const genPositionOption = () =>
    _.map(position, (_position) => (
      <option key={_position.position_id} value={_position.position_id}>
        {_position.position_name}
      </option>
    ));

  return (
    <>
      <div>
        <div>
          <div>
            <div>
              <div>
                <h2 className="text-gray-800 text-xl font-semibold py-1">
                  แก้ไขข้อมูลพนักงาน
                </h2>
                <div className="flex justify-between py-6">
                  <Button variant="outline" onClick={() => history.goBack()}>
                    กลับ
                  </Button>
                </div>
              </div>
              {findEmployee()}
              <div className="flex content-center items-center justify-center h-full mb-12">
                <div className="w-full lg:w-6/12 px-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                          รูปภาพประจำตัว
                        </label>
                        <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                          <ImageUpload
                            images={image}
                            setImages={setImage}
                            preview_size="100px"
                          />
                        </div>
                      </div>
                      {/*
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            อีเมล
                          </label>
                          <input
                            name="employee_email"
                            type="email"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="example@email.com"
                            defaultValue={employeeSelect?.employee_email}

                          />
                        </div>
                      </div> */}
                      {/* <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            รหัสผ่าน
                          </label>
                          <input
                            name="password"
                            type="password"
                            required
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="รหัสผ่านอย่างน้อย 6 ตัวอักษร"
                            ref={register}
                          />
                        </div>
                      </div> */}
                      {/* <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ยืนยันรหัสผ่าน
                          </label>
                          <input
                            name="confirm_password"
                            type="password"
                            required
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ยืนยันรหัสผ่าน"
                            ref={register}
                          />
                        </div>
                      </div> */}

                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ชื่อ
                          </label>
                          <input
                            name="employee_first_name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ชื่อ"
                            defaultValue={employeeSelect?.employee_first_name}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            นามสกุล
                          </label>
                          <input
                            name="employee_last_name"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="นามสกุล"
                            defaultValue={employeeSelect?.employee_last_name}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            เพศ
                          </label>

                          <select
                            name="employee_gender"
                            required
                            ref={register}
                            value={genderSelect}
                            onChange={(e) => setGenderSelect(e.target.value)}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          >
                            <option value={UserInfo.GENDER.MALE}>
                              {UserInfo.GENDER.MALE}
                            </option>
                            <option value={UserInfo.GENDER.FEMALE}>
                              {UserInfo.GENDER.FEMALE}
                            </option>
                            <option value={UserInfo.GENDER.OTHERS}>
                              {UserInfo.GENDER.OTHERS}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            เบอร์โทรศัพท์
                          </label>

                          <input
                            name="employee_phone_number"
                            type="text"
                            required
                            ref={register}
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="เบอร์โทรศัพท์"
                            defaultValue={employeeSelect?.employee_phone_number}
                          />
                        </div>
                      </div>

                      <div className="w-full  px-4">
                        <div className="relative w-full  mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            ที่อยู่
                          </label>
                          <textarea
                            name="employee_address"
                            required
                            ref={register}
                            rows="5"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            placeholder="ที่อยู่"
                            defaultValue={employeeSelect?.employee_address}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          แผนก
                        </label>
                        <select
                          name="department_id"
                          required
                          disabled
                          ref={register}
                          value={departmentSelect}
                          onChange={(e) => setDepartmentSelect(e.target.value)}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          {getDepartmentOption()}
                        </select>
                      </div>
                    </div>

                    <div className="w-full  px-4">
                      <div className="relative w-full  mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          ตำแหน่ง
                        </label>
                        <select
                          name="position_id"
                          required
                          disabled
                          ref={register}
                          value={positionSelect}
                          onChange={(e) => setPositionSelect(e.target.value)}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        >
                          {genPositionOption()}
                        </select>
                      </div>
                    </div>

                    <div className="text-center px-4 gap-0 py-3">
                      <Button
                        isFullWidth
                        colorScheme="yellow"
                        variant="solid"
                        type="submit"
                      >
                        แก้ไข
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
